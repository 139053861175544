import { Z_INDEXES } from '@clarity-website/utils/ZIndexConstants';
import useDismissAfter from '@clarity-website/utils/useDismissAfter';
import { ReactChildren } from '@clarity-website/utils/utility-types';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

type Props = {
  timeout?: number;
  autoDismiss?: boolean;
  condition: boolean;
  marginTop?: number;
  display?: 'fixed' | 'absolute';
  children: (props: { dismiss: () => void }) => ReactChildren;
};

/**
 *
 * @component A toast is dismissable non-modal ui element that appears at the top of the screen
 * to alert with some information
 * @param timeout 6000 ms recommended.
 * @param autoDismiss if false is passed, Toast will stay until manually dismissed
 * @param marginTop if toast should dangle at a distance from top, pass a number
 * @param display pass 'absolute' to make relative to nearest parent, 'fixed' for top of page
 */
export default function Toast({
  timeout = 6000,
  autoDismiss = true,
  display = 'fixed',
  condition,
  marginTop,
  children,
}: Props) {
  const defaultTimeout = autoDismiss ? timeout : undefined;
  const { show, dismiss } = useDismissAfter({
    timeout: defaultTimeout,
    trigger: condition,
  });
  const transitions = useTransition(show, {
    from: { transform: `translate3d(0,-100vh,0)`, opacity: 0 },
    enter: { transform: `translate3d(0,0,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,-100vh,0)`, opacity: 0 },
  });

  return (
    <Container display={display}>
      <RelativeWrapper>
        {transitions(
          (props, item) =>
            item && (
              <Wrapper style={props} aria-label="alert" marginTop={marginTop}>
                {children({ dismiss })}
              </Wrapper>
            ),
        )}
      </RelativeWrapper>
    </Container>
  );
}

const Container = styled.div<{ display: 'fixed' | 'absolute' }>`
  position: ${({ display }) => `${display};`};
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${Z_INDEXES.tooltip};
`;

const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(({ marginTop, ...rest }) => <animated.div {...rest} />)<{
  marginTop?: number;
}>`
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px;`};
`;
