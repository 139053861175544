import { ButtonIconPosition } from '@amzn/stencil-react-components/button';
import { IconCross } from '@amzn/stencil-react-components/icons';
import usePWAWorker from '@clarity-website/app/usePWAWorker';
import Toast from '@clarity-website/common/Toast';
import * as serviceWorkerRegistration from '@clarity-website/serviceWorkerRegistration';
import { SquareButton } from '@clarity-website/stencil-migration-helpers/components';
import useShowAfter from '@clarity-website/utils/useShowAfter';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

export default function ServiceWorkerWrapper() {
  const history = useHistory();
  const { skipWaiting, showBanner, displayReload } = usePWAWorker();
  // prevent duplicate banner on login
  const { show: showBannerAfterDelay } = useShowAfter(3000, showBanner);

  const onSWUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      const worker = registration.waiting;
      if (worker) {
        displayReload(worker);
      }
    },
    [displayReload],
  );

  const checkForUpdate = useCallback(
    () =>
      serviceWorkerRegistration.register({
        onUpdate: onSWUpdate,
        immediate: true,
      }),
    [onSWUpdate],
  );

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, [onSWUpdate]);

  const prevHistory = useRef<string | null>(null);
  useEffect(() => {
    // check for updates on each page navigation
    const unlisten = history.listen((location, action) => {
      const { current: prevPath } = prevHistory;
      if (prevPath !== location.pathname && action === 'PUSH') {
        prevHistory.current = location.pathname;
        checkForUpdate();
      }
    });
    return () => unlisten();
  }, [history, checkForUpdate]);

  useEffect(() => {
    function skipWaitingReference() {
      skipWaiting({ initiatingEvent: 'page-reload' });
    }
    // if user refreshes page, tell SW its ok to update
    window.addEventListener('beforeunload', skipWaitingReference);
    return () =>
      window.removeEventListener('beforeunload', skipWaitingReference);
  }, [skipWaiting]);

  useEffect(() => {
    // if a user returns to a page, check for update
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        checkForUpdate();
      }
    });
  }, [checkForUpdate]);

  const reloadPage = () => {
    skipWaiting({ initiatingEvent: 'update-button' });
  };

  return (
    <Toast condition={showBannerAfterDelay} autoDismiss={false} marginTop={26}>
      {({ dismiss }) => (
        <Container>
          <SquareButton
            onClick={reloadPage}
            aria-label="update clarity"
            iconPosition={ButtonIconPosition.Trailing}
            type="button"
            color="neutral0"
          >
            <ReloadContent>
              <Text>Update Clarity to the latest version!</Text>
            </ReloadContent>
          </SquareButton>
          <SquareButton
            onClick={dismiss}
            icon={<IconCross color="neutral0" />}
            aria-label="dont update clarity"
            type="button"
            color="neutral0"
          />
        </Container>
      )}
    </Toast>
  );
}

const Text = styled.div`
  width: max-content;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  height: 50px;
  ${({ theme }) => `
    background: ${theme.color.primary60};
    box-shadow: 0 2px 4px 0 ${theme.color['neutral0-alpha30']};
    color: ${theme.color.neutral0};
    :hover {
      background: ${theme.color.primary70};
    }
  `}
`;

const ReloadContent = styled.div`
  display: flex;
  height: 100%;
  color: white;
  align-items: center;
`;
