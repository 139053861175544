import { useEffect, useRef, useState } from 'react';

type Props = {
  timeout?: number;
  trigger: boolean;
};

export default function useDismissAfter({ timeout, trigger }: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(trigger);
  }, [trigger]);

  const ref = useRef<number>();
  useEffect(() => {
    if (trigger && timeout) {
      ref.current = window.setTimeout(() => setShow(false), timeout);
    }
    if (!trigger) {
      clearTimeout(ref.current);
      setShow(false);
    }

    return () => clearTimeout(ref.current);
  }, [trigger, timeout]);

  const dismiss = () => setShow(false);

  return { show, dismiss };
}
