import { PeriodType } from '@amzn/claritygqllambda';
import {
  PeriodAnchor,
  TimePresence,
} from '@clarity-website/reports/edit/timespan/types';

export interface DomainConfigApiResponse {
  items: DomainConfigApiResponseItem[];
}
export interface DomainConfigApiResponseItem {
  name: string;
  displayName: string;
  description: string;
  configurationMap: {
    [key: string]: any;
  };
  timespanConfigurationMap: {
    [key: string]: {
      timePresences: TimePresence[];
      periodAnchor: PeriodAnchor;
      timeAnchor: string[];
    };
  };
  filterableAttributes: string[];
}

export interface DomainConfigs {
  config: { [domain: string]: DomainConfig };
  filteredAttributes: { [domain: string]: FilteredAttributesConfig };
  rowLevelReportConfig: { [domain: string]: RowLevelReportConfig };
}

export type DomainConfigStringMap = {
  [key: string]: string;
};

export interface DomainConfig {
  displayName: string;
  isSubtotalFeatureEnabled: boolean;
  isRowLevelDomain: boolean;
  isLeaderFiltersAllowedDomain: boolean;
  rowDomainMapping?: string;
  aggregateDomainMapping?: string;
  isExclusionFeatureEnabled?: boolean;
  allowedTeamIds: string[];
  supportedHierarchies?: DomainConfigStringMap;
  isTimespanGroupingEnabled?: boolean;
  supportedPeriodTypes: PeriodType[];
  requiredColumns: string[];
  futureDaysAllowedForSelection: number;
  isPeriodOverPeriodEnabled?: boolean;
  simTicketTemplatesMap: DomainConfigStringMap;
  isLeaderBulkEditEnabled?: boolean;
  isStandardReportsPageAllowed?: boolean;
  isCreateFromScratchAllowed?: boolean;
  tabName: string;
  description: string;
  timespanConfigurationMap: {
    [key: string]: {
      timePresences: TimePresence[];
      periodAnchor: PeriodAnchor;
      timeAnchor: string[];
    };
  };
  filterableAttributes: string[];
}

export interface FilteredAttributesConfig {
  primaryLeaderAttrId: string;
  directManagerAttrId: string;
  directManagerAttrIdGQL: string;
  directsAttrId: string;
  primaryLeaderInDirectsAttrId: string;
  simReportsToAttributeIds: string[];
}

export interface RowLevelReportConfig {
  initialColumns: string[];
  baselineFilterAttrId?: string;
}

export function createDomainConfigs(
  domainConfigItems: DomainConfigApiResponseItem[] = [],
): DomainConfigs {
  const config = domainConfigItems.reduce(
    (
      acc: Record<string, DomainConfig>,
      {
        name,
        configurationMap,
        description,
        displayName,
        filterableAttributes,
        timespanConfigurationMap,
      },
    ) => {
      if (configurationMap) {
        const isRowLevelDomain =
          configurationMap.isRowLevelDomain?.bool || false;
        acc[name as string] = {
          displayName: configurationMap.displayName?.string || '',
          isSubtotalFeatureEnabled:
            configurationMap.isSubtotalFeatureEnabled?.bool || false,
          isRowLevelDomain,
          isLeaderFiltersAllowedDomain: !!(
            configurationMap.mandatoryAttributeConfigForDirects ||
            configurationMap.mandatoryAttributeConfigForPrimary
          ),
          rowDomainMapping: configurationMap.rowDomainMapping?.string,
          aggregateDomainMapping: isRowLevelDomain
            ? name?.replace('_row', '')
            : undefined,
          isExclusionFeatureEnabled:
            configurationMap.isExclusionFeatureEnabled?.bool || false,
          allowedTeamIds: configurationMap.allowedTeamIds?.stringList || [],
          supportedPeriodTypes:
            configurationMap.supportedPeriodType?.stringList || [],
          requiredColumns: configurationMap.requiredColumns?.stringList,
          supportedHierarchies:
            configurationMap.supportedHierarchies?.stringMap || {},
          isPeriodOverPeriodEnabled:
            configurationMap?.isPeriodOverPeriodEnabled?.bool || false,
          isTimespanGroupingEnabled:
            !configurationMap?.disableTimespanGrouping?.bool,
          futureDaysAllowedForSelection: parseInt(
            configurationMap?.futureDaysAllowedForSelection?.string || '0',
          ),
          simTicketTemplatesMap:
            configurationMap.simTicketTemplatesMap?.stringMap || {},
          isLeaderBulkEditEnabled:
            configurationMap.isLeaderBulkEditEnabled?.bool || false,
          description,
          filterableAttributes,
          tabName: displayName,
          timespanConfigurationMap,
          isStandardReportsPageAllowed:
            configurationMap.isStandardReportsPageAllowed?.bool || false,
          isCreateFromScratchAllowed:
            !isRowLevelDomain && name !== 'peoplesoft_query',
        } as DomainConfig;
      }
      return acc;
    },
    {},
  );

  const filteredAttributes = domainConfigItems.reduce(
    (
      acc: Record<string, FilteredAttributesConfig>,
      { name, configurationMap },
    ) => {
      if (
        configurationMap?.mandatoryAttributeConfigForDirects ||
        configurationMap?.mandatoryAttributeConfigForPrimary
      ) {
        acc[name as string] = {
          primaryLeaderAttrId: configurationMap.primaryLeaderAttrId?.string,
          directManagerAttrId: configurationMap.directManagerAttrId?.string,
          directManagerAttrIdGQL:
            configurationMap.directManagerAttrIdGQL?.string,
          directsAttrId: configurationMap.directsAttrId?.string,
          primaryLeaderInDirectsAttrId:
            configurationMap.primaryLeaderInDirectsAttrId?.string,
          simReportsToAttributeIds:
            configurationMap.simReportsToAttributeIds?.stringList,
        } as FilteredAttributesConfig;
      }
      return acc;
    },
    {},
  );

  const rowLevelReportConfig = domainConfigItems.reduce(
    (acc: Record<string, RowLevelReportConfig>, { name, configurationMap }) => {
      if (configurationMap?.isRowLevelDomain?.bool) {
        acc[name as string] = {
          initialColumns: configurationMap.initialColumns?.stringList,
          baselineFilterAttrId: configurationMap.baselineFilterAttrId?.string,
        } as RowLevelReportConfig;
      }
      return acc;
    },
    {},
  );

  return { config, filteredAttributes, rowLevelReportConfig };
}
