import { Domain } from '@amzn/claritygqllambda';
import { AttributeGroup } from '@clarity-website/maintenance/form/common/Constants';
import filterMap, {
  FilterConfigInterface,
} from '@clarity-website/reports/filters/filter-config';
import {
  AttributeInterface,
  Item as ItemType,
  MetricsInterface,
  TimespanInterface,
} from '@clarity-website/reports/filters/filter-types';
import { getTimespanFilters } from '@clarity-website/reports/filters/timespan-config';

export type FilterDictionaryType = Map<string, ItemType>;

export default function constructFilterConfig(
  availableAttributes: AttributeInterface[],
  availableMetrics: MetricsInterface[] = [],
  domain: string,
  isRowLevelDomain?: boolean,
) {
  const attributeMap = new Map();
  const metricColumnMap = new Map(); // In row level report, metric section actually is the same with attribute section, it just displays a different group of attributes
  const metricMap = new Map();
  const timespanMap = new Map();
  const availableTimespans = getTimespanFilters(domain || Domain.Location);

  availableAttributes.forEach((attribute) => {
    if (
      attribute.attributeGroup === AttributeGroup.Metric &&
      isRowLevelDomain
    ) {
      metricColumnMap.set(attribute.id, attribute);
    } else {
      attributeMap.set(attribute.id, attribute);
    }
  });

  availableMetrics.forEach((metric: MetricsInterface) => {
    metricMap.set(metric.id, metric);
  });

  availableTimespans.forEach((timespan: TimespanInterface) => {
    timespanMap.set(timespan.id, timespan);
  });

  const filterDictionary: FilterDictionaryType = new Map([
    ...attributeMap,
    ...metricMap,
    ...metricColumnMap,
    ...timespanMap,
  ]);

  const filterConfig: FilterConfigInterface = {
    ...filterMap,
    attributes: {
      ...filterMap.attributes,
      filterValues: attributeMap,
    },
    metrics: {
      ...filterMap.metrics,
      filterValues: metricMap,
    },
    timespan: {
      ...filterMap.timespan,
      filterValues: timespanMap,
    },
    metricColumns: {
      ...filterMap.metricColumns,
      filterValues: metricColumnMap,
    },
  };

  return {
    filterDictionary,
    filterConfig,
  };
}
