import {
  ButtonIconPosition,
  ButtonProps,
} from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import {
  BoxModelMixin,
  BoxModelMixinProps,
  FlexPropertiesMixin,
  FlexPropertiesMixinProps,
} from '@clarity-website/utils/css-mixins';
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  cloneElement,
  forwardRef,
} from 'react';
import styled from 'styled-components';

export const MigrationText = styled(({ alignItems, ...rest }) => (
  <Text {...rest} />
))<BoxModelMixinProps & FlexPropertiesMixinProps>`
  ${BoxModelMixin}
  ${FlexPropertiesMixin}
`;

type SupportedStencilProps = Pick<
  ButtonProps,
  'iconPosition' | 'children' | 'icon' | 'color'
>;
export const SquareButton = forwardRef(
  (
    props: ButtonHTMLAttributes<HTMLButtonElement> &
      BoxModelMixinProps &
      SupportedStencilProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        type="button"
        ref={ref}
        data-testid="square-button"
        {...props}
        onClick={(e) => {
          if (props.onClick) {
            e.stopPropagation();
            props.onClick(e);
          }
        }}
      >
        <Row>
          {props.icon &&
          (props.iconPosition === ButtonIconPosition.Leading ||
            !props.iconPosition) ? (
            <IconBumperLeading data-testid="leading-icon">
              {cloneElement(props.icon as JSX.Element, {
                'aria-hidden': true,
              })}
            </IconBumperLeading>
          ) : null}

          {props.children ? (
            <TextWrapper data-testid="text">
              <ButtonText>{props.children}</ButtonText>
            </TextWrapper>
          ) : null}
          {props.icon && props.iconPosition === ButtonIconPosition.Trailing ? (
            <IconBumperTrailing data-testid="trailing-icon">
              {cloneElement(props.icon as JSX.Element, {
                'aria-hidden': true,
              })}
            </IconBumperTrailing>
          ) : null}
        </Row>
      </Button>
    );
  },
);

const ButtonText = styled(MigrationText)`
  position: relative;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const IconBumperLeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: 0 auto;
`;
const Button = styled.button<BoxModelMixinProps & SupportedStencilProps>`
  background: transparent;
  text-decoration: none;
  appearance: none;
  border: 2px solid transparent;
  padding: 4px;
  cursor: pointer;
  border: 2px solid transparent;
  outline: 2px solid transparent;
  display: flex;
  align-items: center;
  color: white;
  ${({ theme, color }) => `
    :hover {
      ${IconBumperLeading} {
        svg > path {
          fill: ${!color && theme.color.primary70};
        }
      }
      ${ButtonText} {
        :after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0px;
          right: 0;
          background: ${color || theme.color.primary70};
          height: 1px;
        }
      }
    }
    :active,
    :focus {
      outline-color: ${color || theme.color.primary70};
    }
  `};
`;

const IconBumperTrailing = styled.div`
  margin-left: auto;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
