import {
  DisplayStyleType,
  MetricStatusType,
  OperatorType,
  PeriodType,
  ReportGroupType,
  ReportType,
  ValueType,
} from '@amzn/claritygqllambda';
import { GoalSettingOutput } from '@amzn/claritygqllambda/dist/generated/graphql';
import { ParentSites } from '@clarity-website/pages/browse/config/reports';
import { Principal } from '@clarity-website/pages/search-reports/queries/user-permissions-queries';
import { TimespanSchema } from '@clarity-website/reports/filters/timespan-config';
import {
  AccessStatus,
  ResultStatus,
  WidgetType,
} from '@clarity-widgets/widget-types';

/** Not ValueType itself, but the value of a cell which may have a corresponding ValueType */
export enum MaskValues {
  NA = 'N/A',
  Locked = 'Locked',
  PRIVACY_LOCK = 'PRIVACY_LOCK',
}

export interface MetricStatus {
  name?: string;
  id: string;
  status: { status: MetricStatusType; timestamp: number };
  datasources: {
    name?: string;
    id: string;
  }[];
}

export interface CellInterface {
  value?: string | null;
  valueType: ValueType;
  odm?: boolean;
  metricId?: string;
  precision?: number;
  debugValue?: any;
  isGroupingAttribute?: boolean;
  key?: string;
}
export interface FormattedCellInterface {
  value: string | null;
  valueType: string;
}

export interface TableCellInterface {
  key: string;
  isSubtotalRow: boolean;
  value: string;
  valueType: string;
}

export interface ColumnMetadataInterface {
  isHeader?: boolean;
  id?: string;
  title?: string;
  timespanGroup?: string;
  group: string | undefined | null;
  periodType?: PeriodType;
  timespanKey?: string;
  metricId?: string;
  timespanIndex?: number;
  key?: string;
}

export interface RowInterface {
  cells: CellInterface[];
  isSubtotalRow?: boolean;
  timespanKey?: string;
  site?: string | null;
  metric?: string;
}

export interface AttributeInterface {
  label: string;
  column: number;
  row: number;
  attributeGroup: string;
}

export interface TableSGInterface {
  rows: RowInterface[];
  id: string;
}

export interface TableDemarcatorsInterface {
  [key: string]: boolean;
}

export interface AttrsAndIndexInterface {
  groupingIndexes: number[];
  attIndexMap: {
    [key: string]: boolean;
  };
}

export interface ReportTemplateOutput {
  id: string;
  name: string;
  url: string;
  description: string;
  periodTypes: PeriodType;
  businessLines: string[];
  geos: string[];
  countries: string[];
  regions: string[];
  sites: string[];
  orgs: string[];
  updatedTime: string[];
  updatedBy: string[];
  domain: string;
}

export interface ReportGroupInput {
  id: string;
  name: string;
  description?: string;
  parentReportGroupId: string;
  reportGroupType: ReportGroupType;
  tabIds: string[];
  domain: string;
  version?: number;
  principals: Principal[];
}

export interface ReportGroupOutput {
  id: string;
  name: string;
  description?: string;
  parentReportGroupId?: string;
  reportGroupType: ReportGroupType;
  sheets: ReportGroupSheet[];
  domain: string;
  domains: string[];
  principals: Principal[];
  ownedBy: string;
  url?: string;
  version: number;
  parentSite?: ParentSites;
  reportGoals?: GoalSettingOutput[];
  amazonKPIGoals?: GoalSettingOutput[];
}

export interface ReportGroupSheet {
  id: string;
  name: string;
  isActive?: boolean;
  ownedBy?: string;
  tableIds: string[];
  createdTime?: number;
  createdBy?: string;
  updatedTime?: number;
  updatedBy?: string;
  version?: number;
}

export type ReportFilter = {
  attributeId: string;
  operator: OperatorType;
  values: string[];
  filterValue?: string;
  displayValue?: string;
};

export interface ReportFilterOperands {
  [key: string]: string[];
}

export interface ReportFilterRule {
  [OperatorType.And]?: ReportFilterRule[];
  [OperatorType.Or]?: ReportFilterRule[];
  [OperatorType.In]?: ReportFilterOperands;
  [OperatorType.NotIn]?: ReportFilterOperands;
}

export interface ReportFilterV2 {
  [OperatorType.And]: ReportFilterRule[];
}

export interface PolicyRule {
  filters: ReportFilter[];
  defaultRule: boolean;
  predicate: string;
}

export type ReportTemplateInterface = {
  id: string;
  name: string;
  widgetType?: WidgetType;
  reportType?: ReportType;
  rootReportTemplateId?: string;
  reportSubType?: string;
  displayStyle: DisplayStyleType;
  isActive: boolean;
  filters: ReportFilter[];
  filtersV2?: string;
  description: string;
  createdTime: string;
  updatedTime: string;
  policy: {
    rules: PolicyRule[];
  };
  version: number;
  aliases: string[];
  selectedMetricIds: string[];
  selectedGroupingAttributeIds: string[];
  selectedTimespans?: TimespanSchema[];
  periodTypes: string[];
  businessLines: string[];
  metadataType: string;
  updatedBy: string;
  geographies: string[];
  countries: string[];
  regions: string[];
  sites: string[];
  url?: string;
  domain: string;
  parentReportTemplateId?: string;
};

export type EmptyReportTemplateInterface = {
  id: string;
  name: string;
  reportType: ReportType;
  displayStyle: DisplayStyleType;
  filters: ReportFilter[];
  filtersV2?: string;
  selectedMetricIds: string[];
  selectedGroupingAttributeIds: string[];
  selectedTimespans: TimespanSchema[];
  domain: string;
};

export interface ReportDataInterface {
  id: string;
  link?: string;
  errorMessage?: string | null;
  rows: RowInterface[];
  resultStatus: ResultStatus;
  updatedTime?: number;
  columnMetadata: ColumnMetadataInterface[];
  accessStatus?: AccessStatus;
}
