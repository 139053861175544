import { record } from '@clarity-website/common/Analytics';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { messageSW } from 'workbox-window';

const serviceWorkerAtom = atom<ServiceWorker | null>(null);
const reloadAtom = atom(false);

type InitiatingEvent = 'update-button' | 'page-reload' | 'auth';
export default function usePWAWorker() {
  const [showBanner, setShowBanner] = useAtom(reloadAtom);
  const [waitingWorker, setWaitingWorker] = useAtom(serviceWorkerAtom);

  const dismissReload = useCallback(
    () => setShowBanner(false),
    [setShowBanner],
  );

  const displayReload = useCallback(
    (worker: ServiceWorker | null) => {
      setShowBanner(true);
      setWaitingWorker(worker);
    },
    [setShowBanner, setWaitingWorker],
  );

  const skipWaiting = useCallback(
    ({
      login,
      initiatingEvent,
    }: {
      login?: string;
      initiatingEvent?: InitiatingEvent;
    } = {}) => {
      if (waitingWorker) {
        record({
          name: 'updateClarity',
          attributes: { login, initiatingEvent },
        });
        messageSW(waitingWorker, { type: 'SKIP_WAITING' });
      }
      dismissReload();
    },
    [waitingWorker, dismissReload],
  );

  return {
    waitingWorker,
    skipWaiting,
    showBanner,
    displayReload,
  };
}
