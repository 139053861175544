import {
  FilterRule,
  FilterV2,
  OperatorType,
  ReportGroupType,
} from '@amzn/claritygqllambda';
import { useUserState } from '@clarity-website/common/UserProvider';
import useDomainConfigs from '@clarity-website/config/useDomainConfigs';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import useClarityClient from '@clarity-website/lib/clients/useClarityClient';
import { useGetSelectedLeader } from '@clarity-website/pages/home/hooks/useSelectedLeader';
import useGetRoles from '@clarity-website/pages/home/onboarding/hooks/useGetRoles';
import {
  PreferenceType,
  getUISetting,
} from '@clarity-website/queries/UISettingQueries';
import {
  LeaderAttribute,
  useGetLeaderAttributeIdsForMultiDomains,
} from '@clarity-website/reports/edit/useGetLeaderAttributeIds';
import { getDefaultLeaderInputType } from '@clarity-website/reports/useFetchRawReport';
import useQueryParams from '@clarity-website/utils/useQueryParams';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql.macro';
import { useCallback, useMemo } from 'react';

export const dynamicLeaderKey = 'dynamicLeader';
const defaultLeader = 'dherring';

export const setSelectedDynamicLeaderQuery = gql`
  mutation setBatchUISettings($settings: [BatchUISettingInput!]!) {
    setBatchUISettings(settings: $settings)
  }
`;

export const useGetSelectedDynamicLeader = (domain: string) => {
  const client = useClarityClient();
  const { data } = useQuery([dynamicLeaderKey, domain], () =>
    getUISetting(client, `${dynamicLeaderKey}#${domain}`),
  );
  return data;
};

export const updateSelectedDynamicLeader = async (
  domains: string[],
  leader: string,
  client: ClarityClient,
): Promise<boolean> => {
  const batchDynamicLeaderSettings = domains.map((currDomain) => ({
    key: `${dynamicLeaderKey}#${currDomain}`,
    setting: {
      preferenceType: PreferenceType.String,
      stringPreference: leader,
    },
  }));
  return client
    .gql({
      request: setSelectedDynamicLeaderQuery,
      variables: { settings: batchDynamicLeaderSettings },
    })
    .then((data) => data?.setBatchUISettings);
};

type DynamicLeaderMutationInput = {
  domains: string[];
  leader: string;
};

export function useDynamicLeaderMutation() {
  const client = useClarityClient();
  const queryCache = useQueryClient();

  return useMutation(
    (input: DynamicLeaderMutationInput) =>
      updateSelectedDynamicLeader(input.domains, input.leader, client),
    {
      onMutate: ({ domains, leader }) => {
        domains.forEach((domain) => {
          queryCache.cancelQueries([dynamicLeaderKey, domain]);
          queryCache.setQueryData([dynamicLeaderKey, domain], leader);
        });
      },
    },
  );
}

export function useGetDefaultDynamicLeader({
  isLoading,
  isManager,
  isLeader,
  domain,
}: getDefaultLeaderInputType): string | undefined {
  const userLogin = useUserState().user?.login;
  const previouslySelectedLeader = useGetSelectedDynamicLeader(
    domain || 'organization',
  );
  const { selectedLeader: landingPageLeader } = useGetSelectedLeader();
  // Will default the user to themselves if they are a business leader or manager,
  // Otherwise will default to their landing page leader.
  // If they do not have a landing page leader, will default to dherring or org level 3 leader once we have data
  if (previouslySelectedLeader) {
    return previouslySelectedLeader;
  }
  if (!isLoading && (isLeader || isManager)) {
    return userLogin;
  } else if (!isLoading && landingPageLeader) {
    return landingPageLeader;
  } else if (!isLoading) {
    return defaultLeader;
  }
}

export function useCreateLeaderFilter(
  domains?: string[],
  reportGroupType?: string,
  hasSelectedLeader = true,
) {
  const shouldCreateDynamicFilter =
    reportGroupType === ReportGroupType.DynamicFilter && hasSelectedLeader;

  const domainList = useMemo(() => domains || [], [domains]);

  const leaderAttributes = useGetLeaderAttributeIdsForMultiDomains(domainList);

  const { isLoading: isRolesLoading, data: { isManager, isLeader } = {} } =
    useGetRoles();

  const leader = useGetDynamicLeader({
    isLoading: isRolesLoading,
    isManager,
    isLeader,
    domain: domainList.length > 0 ? domainList[0] : 'rto',
  });

  const { data: domainConfigs } = useDomainConfigs();

  const generatePrimaryLeaderFilter = useCallback(
    (leaderAttribute: LeaderAttribute, leader: string) => {
      if (leaderAttribute.primaryLeaderInDirectsAttrId) {
        return {
          [OperatorType.In]: {
            [leaderAttribute.primaryLeaderInDirectsAttrId]: [leader],
          },
        };
      }
    },
    [],
  );

  const generatePrimaryOrDirectAttFilter = useCallback(
    (
      leaderAttribute: LeaderAttribute,
      leader: string,
      isRowLevelDomain?: boolean,
    ): FilterRule | undefined => {
      // For row level reports we generate primaryLeaderAttrId filter only
      if (
        isRowLevelDomain &&
        leaderAttribute.primaryLeaderAttrId &&
        shouldCreateDynamicFilter &&
        !isRolesLoading
      ) {
        return {
          [OperatorType.In]: {
            [leaderAttribute.primaryLeaderAttrId]: [leader],
          },
        };
      }
      // For aggregated reports, we will need to include directsAttrId in the filter for the over-all column
      // For aggregated reports with no directsAttrId like PE domain, we will only have primaryLeaderAttrId filter
      if (
        (leaderAttribute.primaryLeaderAttrId ||
          leaderAttribute.directsAttrId) &&
        shouldCreateDynamicFilter &&
        !isRolesLoading
      ) {
        const filterRules: FilterRule[] = [];
        if (leaderAttribute.primaryLeaderAttrId) {
          filterRules.push({
            [OperatorType.In]: {
              [leaderAttribute.primaryLeaderAttrId]: [leader],
            },
          });
        }

        if (leaderAttribute.directsAttrId) {
          filterRules.push({
            [OperatorType.In]: {
              [leaderAttribute.directsAttrId]: [leader],
            },
          });
        }

        if (filterRules.length > 0) {
          return {
            [OperatorType.Or]: filterRules,
          };
        }
      }
    },
    [isRolesLoading, shouldCreateDynamicFilter],
  );

  const generateFilters = () => {
    if (
      !(
        leader &&
        domains &&
        domains.length > 0 &&
        domainConfigs?.config &&
        shouldCreateDynamicFilter
      )
    ) {
      return [];
    }

    return leaderAttributes.reduce(
      (generatedFilters: FilterV2[], leaderAttribute) => {
        const domain = leaderAttribute.domain;
        const isRowLevelDomain =
          !!domain && domainConfigs?.config[domain]?.isRowLevelDomain;

        const primaryLeaderFilter = generatePrimaryLeaderFilter(
          leaderAttribute,
          leader,
        );

        if (primaryLeaderFilter) {
          generatedFilters.push({
            [OperatorType.And]: [primaryLeaderFilter],
          });
        }

        const directFilter = generatePrimaryOrDirectAttFilter(
          leaderAttribute,
          leader,
          isRowLevelDomain,
        );
        if (directFilter) {
          generatedFilters.push({
            [OperatorType.And]: [directFilter],
          });
        }
        return generatedFilters;
      },
      [],
    );
  };

  return useMemo(generateFilters, [
    leader,
    shouldCreateDynamicFilter,
    domains,
    domainConfigs?.config,
    leaderAttributes,
    generatePrimaryLeaderFilter,
    generatePrimaryOrDirectAttFilter,
  ]);
}

export function useGetDynamicLeader({
  isLoading,
  isManager,
  isLeader,
  domain,
}: getDefaultLeaderInputType) {
  const query = useQueryParams();
  const leaderFromURL = query.get('leader');
  const defaultLeader = useGetDefaultDynamicLeader({
    isLoading,
    isManager,
    isLeader,
    domain,
  });
  return leaderFromURL || defaultLeader;
}
