// undocumented, taken from Stencil's website
export const Z_INDEXES = {
  header: 600,
  tooltip: 700,
  layout: 800,
  modal: 900,
  highest: 1000,
};

// Add this to Z-Index of tooltips in modals
export const TOOLTIP_IN_MODAL_OFFSET = 1;
