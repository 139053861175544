import { ReportType } from '@amzn/claritygqllambda';
import { useAnalytics } from '@clarity-website/common/useAnalytics';
import { currentUserStateAtom } from '@clarity-website/common/userProviderAtoms';
import { ReportGroupSheet } from '@clarity-website/reports/report-types';
import { rumClient } from '@clarity-website/rum';
import { ResultStatus } from '@clarity-widgets/widget-types';
import getUnixTime from 'date-fns/getUnixTime';
import minutesToSeconds from 'date-fns/minutesToSeconds';
import { useAtomValue } from 'jotai';
import { intersection } from 'ramda';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const NEW_REPORT_TIME_THRESHOLD_SECONDS = minutesToSeconds(2);

export enum LatencyCategory {
  REPORT = 'report',
  USER_NAVIGATED_AWAY_REPORT = 'user_is_idle_report',
}

export const LatencyCategoryMetricName: Record<LatencyCategory, string> = {
  [LatencyCategory.REPORT]: 'reportLoadingTime',
  [LatencyCategory.USER_NAVIGATED_AWAY_REPORT]:
    'userNavigatedAwayReportLoadingTime',
};

export function useLogPerceivedLatency({
  isFetchingData,
  setEndTimeCondition,
  reportType,
  category,
  athenaQueryEndTime,
  traceId,
  reportModifiedTime,
  rows = -1,
  timespanTuples = -1,
  resultStatus,
  shouldFetchReport,
  reportId,
  sheet,
  recordedTablesInSheet,
  rateLimiterStatus,
  domain,
  firstRowSize = 0,
}: RecordLatencyData) {
  const { record } = useAnalytics();
  const { user } = useAtomValue(currentUserStateAtom);
  const monitoringStartTime = useRef(0);
  const wasFetchingData = useRef(false);
  const positionOfTableInSheet = reportId && sheet?.tableIds?.indexOf(reportId);
  const isCached =
    !!athenaQueryEndTime &&
    athenaQueryEndTime * 1000 < monitoringStartTime.current
      ? 'isCached'
      : 'isNotCached';
  const currentTimeSeconds = getUnixTime(new Date());
  const reportUpdateStatus =
    !!reportModifiedTime &&
    currentTimeSeconds - reportModifiedTime < NEW_REPORT_TIME_THRESHOLD_SECONDS
      ? 'newReport'
      : 'existingReport';
  const [latency, setLatency] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (shouldFetchReport) {
      monitoringStartTime.current = new Date().getTime();
    }
  }, [shouldFetchReport]);
  useEffect(() => {
    if (isFetchingData && !wasFetchingData.current) {
      monitoringStartTime.current = new Date().getTime();
      wasFetchingData.current = true;
    }
    if (setEndTimeCondition && wasFetchingData.current) {
      setLatency(new Date().getTime() - monitoringStartTime.current);
    }
  }, [
    setEndTimeCondition,
    isFetchingData,
    wasFetchingData,
    monitoringStartTime,
    category,
    isCached,
    reportUpdateStatus,
    athenaQueryEndTime,
    reportModifiedTime,
  ]);

  useEffect(() => {
    if (
      traceId &&
      category &&
      LatencyCategoryMetricName[category] &&
      latency !== undefined &&
      latency > 0 &&
      setEndTimeCondition &&
      wasFetchingData.current
    ) {
      wasFetchingData.current = false;
      updateRecordedTables(
        record,
        latency,
        reportId,
        sheet,
        recordedTablesInSheet,
      );

      const estimatedDataSize = firstRowSize * rows;

      record({
        name: LatencyCategoryMetricName[category],
        attributes: {
          traceId,
          ...(!!athenaQueryEndTime && {
            cachedStatus: isCached,
            reportStatus: reportUpdateStatus,
          }),
          rows,
          timespanTuples,
          reportType,
          resultStatus,
          rateLimiterStatus,
          reportId,
          positionOfTableInSheet,
          domain,
          firstRowSize,
          estimatedDataSize,
        },
        metrics: {
          latency,
        },
      });

      rumClient?.recordEvent(LatencyCategoryMetricName[category], {
        traceId,
        ...(!!athenaQueryEndTime && {
          cachedStatus: isCached,
          reportStatus: reportUpdateStatus,
        }),
        rows,
        timespanTuples,
        reportType,
        resultStatus,
        reportId,
        positionOfTableInSheet,
        latency,
        country: user?.country,
        domain,
        firstRowSize,
        estimatedDataSize,
      });

      setLatency(undefined);
    }
  }, [
    record,
    traceId,
    athenaQueryEndTime,
    category,
    isCached,
    reportUpdateStatus,
    latency,
    setEndTimeCondition,
    wasFetchingData,
    reportModifiedTime,
    rows,
    timespanTuples,
    reportType,
    resultStatus,
    reportId,
    positionOfTableInSheet,
    recordedTablesInSheet,
    sheet,
    user?.country,
    rateLimiterStatus,
    domain,
    firstRowSize,
  ]);
}

function updateRecordedTables(
  record: (evt: any) => void,
  latency: number,
  reportId?: string,
  sheet?: ReportGroupSheet,
  recordedTablesInSheet?: MutableRefObject<string[]>,
) {
  if (recordedTablesInSheet?.current && reportId) {
    recordedTablesInSheet.current = [
      ...recordedTablesInSheet.current,
      reportId,
    ];
  }
  const isAllTablesInSheetLoaded =
    recordedTablesInSheet?.current &&
    sheet?.tableIds &&
    intersection(recordedTablesInSheet?.current, sheet?.tableIds)?.length ===
      sheet?.tableIds.length;
  if (isAllTablesInSheetLoaded) {
    record({
      name: 'sheetLoadingTime',
      attributes: { sheetId: sheet?.id },
      metrics: {
        latency,
      },
    });
  }
}

export type RecordLatencyData = {
  isFetchingData: boolean;
  setEndTimeCondition: boolean;
  rateLimiterStatus: string;
  shouldFetchReport?: boolean;
  reportId?: string;
  reportType?: ReportType;
  category?: LatencyCategory;
  athenaQueryEndTime?: number;
  traceId?: string;
  reportModifiedTime?: number;
  rows?: number;
  timespanTuples?: number;
  resultStatus?: ResultStatus;
  sheet?: ReportGroupSheet;
  recordedTablesInSheet?: MutableRefObject<string[]>;
  domain?: string;
  firstRowSize?: number;
};
