// Styling
export const CELL_SIDE_PADDING = 8;
export const minColWidth = 80;
export const maxColWidth = 500;
export const reportRowHeight = 36;

// Configuration
export const MAX_TABLE_COUNT = 6;
export const MAX_TAB_COUNT = 8;
export const MAX_SHEET_NAME_LENGTH = 15;

export const DEFAULT_NEW_TAB_ID = 'new-tab';
export const TAB_ID_SEARCH_KEY = 'tab';
export const TAB_INDEX_SEARCH_KEY = 'tabIndex';
export const TAB_NAME_SEARCH_KEY = 'name';
export const DEFAULT_TAB_NAME_PREFIX = 'Sheet';
export const DEFAULT_TAB_NAME = `${DEFAULT_TAB_NAME_PREFIX} 1`;

// Fullscreen
// FS_BUTTON_TABLE_BOUNDARIES is expressed as a percentage, and is used to clamp
// to the positioning of the button slightly inside of the boundary of the
// button's table. This prevents the parent container from overflowing due to
// the button's focus ring sticking out a little bit. If we want to clamp the
// button's position further inside of table boundaries, we only need to
// increase the value of this constant.
export const FS_BUTTON_TABLE_BOUNDARIES = 0.7;

// Equal to the height and width (in pixels) of a small Stencil button that has
// an icon of default size, and no text.
export const FS_BUTTON_DIMENSIONS = 36;

//TODO: update for each section to reference from this flyout
export enum EditSections {
  Metrics = 'metrics',
  Goals = 'goals',
}
