import { useEffect, useRef, useState } from 'react';

export default function useShowAfter(timeout: number, trigger: boolean) {
  const [show, setShow] = useState(false);

  const ref = useRef<number>();
  useEffect(() => {
    if (trigger) {
      ref.current = window.setTimeout(() => setShow(true), timeout);
    } else {
      clearTimeout(ref.current);
      setShow(false);
    }

    return () => clearTimeout(ref.current);
  }, [trigger, timeout]);

  return { show };
}
