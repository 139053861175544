import { Col } from '@amzn/stencil-react-components/layout';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { ReactComponent as PadLockComponent } from '@clarity-website/assets/svg/padlock.svg';
import ReactMarkdownParser from '@clarity-website/common/banners/ReactMarkdownParser';
import useGetMessageGroup, {
  useMessageBannerDispatchers,
} from '@clarity-website/message-banners/useMessageBanners';
import styled from 'styled-components';

export enum MessageGroupIds {
  REPORT_LEVEL = 'report-level',
  TAB_LEVEL = 'tab-level',
  TABLE_LEVEL = 'table-level',
  EDIT_REPORT_FLYOUT = 'edit-report-flyout',
  CLARITY_APP_LEVEL = 'clarity-app-level',
  MAINTENANCE_APP_LEVEL = 'maintenance-app-level',
  REPORTS_PAGE_LEVEL = 'reports-page-level',
}

/**
 * purple padlock conveys a read only report in the report screen; unfortunately,
 * the representation of a purple padlock does not lend itself to a generalization
 * that nicely fits the into a new semantic category of stencil's MessageBannerType enum.
 *
 * A SIM has been raised to support this natively in Stencil's MessageBanner component
 * https://sim.amazon.com/issues/StencilIntake-1335
 */
export type ClarityMessageBannerType = MessageBannerType | 'PURPLE_PADLOCK';

type MessageGroupContainerProps = {
  messageGroupId: string;
  className?: string;
};

export const displayPriority: Record<ClarityMessageBannerType, number> = {
  [MessageBannerType.Error]: 20,
  [MessageBannerType.Warning]: 15,
  [MessageBannerType.Bias]: 10,
  PURPLE_PADLOCK: 6,
  [MessageBannerType.Informational]: 5,
  [MessageBannerType.Success]: 0,
};

export const DEFAULT_SUCCESS_AUTO_DISMISS_AFTER_SEC = 7000;
export const DEFAULT_WARNING_AUTO_DISMISS_AFTER_SEC = 7000;

export default function MessageGroupContainer({
  messageGroupId,
  className,
}: MessageGroupContainerProps) {
  const { removeMessage } = useMessageBannerDispatchers();
  const getMessageGroup = useGetMessageGroup();
  const messageGroup = getMessageGroup(messageGroupId);

  const messages = messageGroup.sort((a, b) => {
    if (!a.type || !b.type) {
      return 0;
    }
    return displayPriority[b.type] - displayPriority[a.type];
  });

  if (!messages.length) {
    return null;
  }

  return (
    <Col data-testid="message-banner-container" className={className}>
      {messages.map(
        ({
          id,
          messageGroupId,
          text,
          dismissible,
          type,
          metadata,
          autoDismissAfter,
        }) => {
          if (
            Object.values(MessageBannerType).includes(type as MessageBannerType)
          ) {
            return (
              <MessageBanner
                key={id}
                isDismissible={dismissible}
                type={type as MessageBannerType}
                onDismissed={() =>
                  removeMessage({
                    messageGroupId,
                    id,
                  })
                }
                autoDismissAfter={autoDismissAfter}
                {...metadata}
              >
                {/* TODO temporary, will adjust when refactoring banners that are passing components as text to use metadata.children instead */}
                {typeof text === 'string' ? (
                  <>
                    <ReactMarkdownParser content={text} />
                    {metadata?.children}
                  </>
                ) : (
                  metadata?.children ?? text
                )}
              </MessageBanner>
            );
          }
          if (type === 'PURPLE_PADLOCK') {
            return (
              <PurpleBannerWrapper key={id}>
                <MessageBanner
                  isDismissible={dismissible}
                  icon={<PadLockComponent aria-hidden />}
                  onDismissed={() =>
                    removeMessage({
                      messageGroupId,
                      id,
                    })
                  }
                  {...metadata}
                >
                  {metadata?.children ?? text}
                </MessageBanner>
              </PurpleBannerWrapper>
            );
          }
          return null;
        },
      )}
    </Col>
  );
}

const PurpleBannerWrapper = styled.div`
  ${({ theme }) => `
    div {
      border-color: ${theme.color.purple70};
      background: ${theme.color.purple10};
      border-radius: 8px;
    }
    svg {
      color: ${theme.color.purple70};
    }
  `};
`;
