import { PeriodType, ValueType } from '@amzn/claritygqllambda';
import { ParentSites } from '@clarity-website/pages/browse/config/reports';
import {
  TimespanSchema,
  getTimespanFilters,
} from '@clarity-website/reports/filters/timespan-config';

export enum Category {
  Attendance = 'Attendance',
  Adapt = 'Adapt',
  Attrition = 'Attrition',
  Connections = 'Connections',
  Experience = 'Experience',
  Engage = 'Engage',
  Finance = 'Finance',
  Intraday = 'Intraday',
  HITS = 'HITS',
  Panorama = 'Panorama',
  Process = 'Process',
  Staffing = 'Staffing',
  VOA = 'VOA',
  TalentManagement = 'TalentManagement',
  Feedback = 'Feedback',
  TalentAcquisition = 'TalentAcquisition',
  PiProductUsage = 'PiProductUsage',
  Recruiting = 'Recruiting',
  Interview = 'Interview',
  Requisition = 'Requisition',
}

export enum AttributeGroup {
  Site = 'Site',
  Associate = 'Associate',
  Job = 'Job',
  Demographic = 'Demographic',
  Employee = 'Employee',
  Metric = 'Metric',
  Advanced = 'Advanced',
  Location = 'Location',
  Budget = 'Budget',
  HiringManager = 'Hiring Manager',
  JobClassification = 'Job Classification',
  JobDescription = 'Job Description',
  JobDetails = 'Job Details',
  JobInformation = 'Job Information',
  Recruiter = 'Recruiter',
  Sourcer = 'Sourcer',
  CandidateDetails = 'Candidate Details',
  RecruitingActivityDetails = 'Recruiting Activity Details',
  Interview = 'Interview',
}

export enum AttributeSubGroup {
  DEI = 'DEI',
  PerformanceManagement = 'Performance Management',
  Recruiting = 'Recruiting',
  TalentMovement = 'Talent Movement',
  WorkforceComposition = 'Workforce Composition',
  Embark = 'Embark',
  D1MO = 'D1MO',
  ConnectionsExitSurvey = 'Connections Exit Survey',
}

export enum Topic {
  Feature = 'Feature',
  Release = 'Release',
  Bugfix = 'Bug fix',
  All = 'All',
}

export interface IdDisplayValue {
  id: string;
  displayValue: string;
}

export const toDropdownItemArray = (arr: string[]): DropdownItem[] =>
  arr.map((item) => {
    return { label: item, value: item };
  });

export const idDisplayValueToDropdownItemArray = (
  arr: IdDisplayValue[],
): DropdownItem[] =>
  arr.map(({ id, displayValue }) => {
    return { label: `${id} ${displayValue}`, value: id };
  });

export interface DropdownItem {
  label: string;
  value: string;
}

export interface AttributeGroupDropdownItem {
  label: string;
  value: AttributeGroup;
}

export interface AttributeSubGroupDropdownItem {
  label: string;
  value: AttributeSubGroup;
}

export interface TopicDropdownItem {
  label: string;
  value: Topic;
}

export interface PeriodTypeDropdownItem {
  label: string;
  value: PeriodType;
}

export interface ValueTypeDropdownItem {
  label: string;
  value: ValueType;
}

export interface ParentSiteDropdownItem {
  label: string;
  value: ParentSites;
}

export interface FilterDropdownItem {
  label: string;
  value: {
    attributeId: string;
    displayValue?: string;
    filterValue: string;
  };
}

export interface BannerDropdownItem {
  label: string;
  value: string[];
}

export enum MaintenanceItemType {
  Attribute = 'Attribute',
  Metric = 'Metric',
  Policy = 'Policy',
  Report = 'Report',
  Datasource = 'Datasource',
  ReleaseNote = 'ReleaseNote',
  Banner = 'Banner',
}

export interface TimespanDropdownItem {
  label: string;
  value: TimespanSchema;
}
export const standardTimespans: (domain: string) => TimespanDropdownItem[] = (
  domain,
) =>
  getTimespanFilters(domain).map(
    ({
      duration,
      durationType,
      expression,
      name,
      periodType,
      description,
      type,
      temporalAdjusters,
    }) => ({
      label: `${periodType} - ${name}`,
      value: {
        duration,
        durationType,
        expression,
        periodType,
        description,
        type,
        temporalAdjusters,
      },
    }),
  );

export const externalPeriodTypes = (
  supportedPeriodTypes: PeriodType[] = [],
): DropdownItem[] =>
  supportedPeriodTypes.map((periodType) => ({
    label: periodType,
    value: periodType,
  }));

export interface DataTypeItem {
  id?: string;
  name?: string;
  description?: string;
  version?: number;
  workflowStatus?: string;
  domain: string;
  __typename?: string;
}
