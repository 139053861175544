import {
  DurationType,
  PeriodType,
  TimespanComparison,
} from '@amzn/claritygqllambda';
import { timeAnchorToTemporalAdjusterMap } from '@clarity-website/reports/edit/timespan/constants';
import {
  PeriodAnchor,
  TimeAnchor,
  TimePresence,
  TimeUnit,
} from '@clarity-website/reports/edit/timespan/types';
import {
  TemporalAdjuster,
  TemporalAdjusterFunction,
  TimespanSchema,
  TimespanType,
} from '@clarity-website/reports/filters/timespan-config';
import { isThisYear } from 'date-fns';

export interface PeriodTypeUtility {
  timeUnits: { [key in TimePresence]?: TimeUnit };
  // Last: minus, Next: plus, Current: no-op
  lastDirectionAdjusterFunction: TemporalAdjusterFunction;
  nextDirectionAdjusterFunction: TemporalAdjusterFunction;
  durationType: DurationType;
  getAdjusterFunctionConfiguration: (
    anchor: PeriodAnchor,
  ) => AdjusterFunctionConfiguration;
  // For specific date configs
  getComparisonOptionsFn: (start?: Date, end?: Date) => PeriodType[];
}

export interface AdjusterFunctionConfiguration {
  anchorExpression: string;
  anchorTemporalAdjusters: TemporalAdjuster[];
}

export const PeriodTypeUtilityMapping: {
  [key in PeriodType]?: PeriodTypeUtility;
} = {
  [PeriodType.Daily]: {
    timeUnits: {
      [TimePresence.Last]: TimeUnit.Days,
      [TimePresence.Current]: TimeUnit.Days,
    },
    getAdjusterFunctionConfiguration: (anchor: PeriodAnchor) => {
      if (anchor === PeriodAnchor.Begin) {
        return {
          anchorExpression: '0 0 0 * * ? *',
          anchorTemporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
        };
      }
      return {
        anchorExpression: '59 59 23 * * ? *',
        anchorTemporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.endOfDay,
          },
        ],
      };
    },
    lastDirectionAdjusterFunction: TemporalAdjusterFunction.minusDays,
    nextDirectionAdjusterFunction: TemporalAdjusterFunction.plusDays,
    durationType: DurationType.Day,
    getComparisonOptionsFn: (start?: Date, end?: Date) => [
      PeriodType.Daily,
      PeriodType.Weekly,
    ],
  },
  [PeriodType.Weekly]: {
    timeUnits: {
      [TimePresence.Last]: TimeUnit.Weeks,
      [TimePresence.Current]: TimeUnit.WeekToDate,
    },
    getAdjusterFunctionConfiguration: (anchor: PeriodAnchor) => {
      if (anchor === PeriodAnchor.Begin) {
        return {
          anchorExpression: '0 0 0 ? * SUN *',
          anchorTemporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
        };
      }
      return {
        anchorExpression: '59 59 23 ? * SUN *',
        anchorTemporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.endOfSundayWeek,
          },
        ],
      };
    },
    lastDirectionAdjusterFunction: TemporalAdjusterFunction.minusWeeks,
    nextDirectionAdjusterFunction: TemporalAdjusterFunction.plusWeeks,
    durationType: DurationType.Week,
    getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
  },
  [PeriodType.Monthly]: {
    timeUnits: {
      [TimePresence.Last]: TimeUnit.Months,
      [TimePresence.Current]: TimeUnit.MonthToDate,
    },
    getAdjusterFunctionConfiguration: (anchor: PeriodAnchor) => {
      if (anchor === PeriodAnchor.Begin) {
        return {
          anchorExpression: '0 0 0 1 * ? *',
          anchorTemporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
        };
      }
      return {
        anchorExpression: '0 0 0 L * ? *',
        anchorTemporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.endOfMonth,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
      };
    },
    lastDirectionAdjusterFunction: TemporalAdjusterFunction.minusMonths,
    nextDirectionAdjusterFunction: TemporalAdjusterFunction.plusMonths,
    durationType: DurationType.Month,
    getComparisonOptionsFn: (start?: Date, end?: Date) => [
      PeriodType.Monthly,
      PeriodType.Yearly,
    ],
  },
  [PeriodType.Quarterly]: {
    timeUnits: {
      [TimePresence.Last]: TimeUnit.Quarters,
      [TimePresence.Current]: TimeUnit.QuarterToDate,
    },
    getAdjusterFunctionConfiguration: (anchor: PeriodAnchor) => {
      if (anchor === PeriodAnchor.Begin) {
        return {
          anchorExpression: '0 0 0 1 */3 ? *',
          anchorTemporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
        };
      }
      return {
        anchorExpression: '0 0 0 L */3 ? *',
        anchorTemporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.endOfQuarter,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
      };
    },
    lastDirectionAdjusterFunction: TemporalAdjusterFunction.minusQuarters,
    nextDirectionAdjusterFunction: TemporalAdjusterFunction.plusQuarters,
    durationType: DurationType.Quarter,
    getComparisonOptionsFn: (start?: Date, end?: Date) => [
      PeriodType.Quarterly,
      PeriodType.Yearly,
    ],
  },
  [PeriodType.Yearly]: {
    timeUnits: {
      [TimePresence.Last]: TimeUnit.Years,
      [TimePresence.Current]: TimeUnit.YearToDate,
    },
    getAdjusterFunctionConfiguration: (anchor: PeriodAnchor) => {
      if (anchor === PeriodAnchor.Begin) {
        return {
          anchorExpression: '0 0 0 1 1 ? *',
          anchorTemporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
        };
      }
      return {
        anchorExpression: '0 0 0 L 12 ? *',
        anchorTemporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.endOfYear,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
      };
    },
    lastDirectionAdjusterFunction: TemporalAdjusterFunction.minusYears,
    nextDirectionAdjusterFunction: TemporalAdjusterFunction.plusYears,
    durationType: DurationType.Year,
    getComparisonOptionsFn: (start?: Date, end?: Date) =>
      start && isThisYear(start) ? [] : [PeriodType.Yearly],
  },
};

type RollingDataConfig = {
  anchors?: TimeAnchor[];
  timespanSchemaFn: (
    duration?: number,
    timeAnchor?: TimeAnchor,
    comparisons?: TimespanComparison[],
  ) => TimespanSchema;
  comparisonOptions?: PeriodType[];
};

export const generateRollingDateConfigs = (timespanConfigurationMap: {
  [key: string]: {
    timePresences: TimePresence[];
    periodAnchor: PeriodAnchor;
    timeAnchor: string[];
  };
}): {
  [key in TimePresence]?: {
    [key in TimeUnit]?: RollingDataConfig;
  };
} => {
  return Object.entries(timespanConfigurationMap).reduce<{
    [key in TimePresence]?: {
      [key in TimeUnit]?: RollingDataConfig;
    };
  }>((acc, [periodTypeString, timespanConfig]) => {
    timespanConfig.timePresences.forEach((timePresence) => {
      if (!acc[timePresence]) {
        acc[timePresence] = {};
      }
      const periodType =
        PeriodType[periodTypeString as keyof typeof PeriodType];
      const periodTypeUtilityMapping = PeriodTypeUtilityMapping[periodType]!;
      const timeUnit = periodTypeUtilityMapping.timeUnits[timePresence];

      const timespanSchemaFn = (
        duration = 1,
        anchor = TimeAnchor.CurrentYear,
        comparisons?: TimespanComparison[],
      ) => {
        let directionAdjusters: TemporalAdjuster[] = [];
        if (timePresence === TimePresence.Last) {
          directionAdjusters = [
            {
              arguments: [duration.toString()],
              function: periodTypeUtilityMapping.lastDirectionAdjusterFunction,
            },
          ];
        } else if (timePresence === TimePresence.Next) {
          directionAdjusters = [
            {
              arguments: [duration.toString()],
              function: periodTypeUtilityMapping.nextDirectionAdjusterFunction,
            },
          ];
        }
        return {
          duration,
          durationType: periodTypeUtilityMapping.durationType,
          expression: periodTypeUtilityMapping.getAdjusterFunctionConfiguration(
            timespanConfig.periodAnchor,
          ).anchorExpression,
          periodType,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            ...directionAdjusters,
            ...periodTypeUtilityMapping.getAdjusterFunctionConfiguration(
              timespanConfig.periodAnchor,
            ).anchorTemporalAdjusters,
          ],
          type:
            timePresence === TimePresence.Current
              ? TimespanType.Current_Period
              : TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        };
      };
      if (timeUnit) {
        acc[timePresence]![timeUnit] = {
          anchors:
            timePresence === TimePresence.Current
              ? [TimeAnchor.CurrentYear]
              : timespanConfig.timeAnchor.map(
                  (m) => TimeAnchor[m as keyof typeof TimeAnchor],
                ),
          timespanSchemaFn,
        };
      }
    });
    return acc;
  }, {});
};

export type SpecificDateConfig = {
  timespanSchemaFn: (
    duration: number,
    epoch: number,
    timezone: string,
    comparisons?: TimespanComparison[],
  ) => TimespanSchema;
  getComparisonOptionsFn: (start?: Date, end?: Date) => PeriodType[];
};

export const generateSpecificDateConfigs = (timespanConfigurationMap: {
  [key: string]: {
    timePresences: TimePresence[];
    periodAnchor: PeriodAnchor;
    timeAnchor: string[];
  };
}): {
  [key in TimeUnit]?: SpecificDateConfig;
} => {
  return Object.entries(timespanConfigurationMap).reduce<{
    [key in TimeUnit]?: SpecificDateConfig;
  }>((acc, [periodTypeString, timespanConfig]) => {
    const periodType = PeriodType[periodTypeString as keyof typeof PeriodType];
    const periodTypeUtilityMapping = PeriodTypeUtilityMapping[periodType]!;
    const timespanSchemaFn = (
      duration: number,
      epoch: number,
      timezone: string,
      comparisons?: TimespanComparison[],
    ) => ({
      duration,
      durationType: periodTypeUtilityMapping.durationType,
      expression: periodTypeUtilityMapping.getAdjusterFunctionConfiguration(
        timespanConfig.periodAnchor,
      ).anchorExpression,
      periodType,
      temporalAdjusters: [
        {
          arguments: [epoch.toString(), timezone],
          function: TemporalAdjusterFunction.identity,
        },
        ...periodTypeUtilityMapping.getAdjusterFunctionConfiguration(
          timespanConfig.periodAnchor,
        ).anchorTemporalAdjusters,
      ],
      type: TimespanType.Cron_Sequence,
      ...(comparisons && { comparisons }),
    });
    const timeUnit = periodTypeUtilityMapping.timeUnits[TimePresence.Last];
    if (timeUnit) {
      acc[timeUnit] = {
        getComparisonOptionsFn: periodTypeUtilityMapping.getComparisonOptionsFn,
        timespanSchemaFn,
      };
    }
    return acc;
  }, {});
};
