import { pipe } from 'ramda';
import { css } from 'styled-components';

interface DirectionInterface {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const removeFalseyValues = (cssProps: (string | boolean)[]): string[] =>
  cssProps.filter((prop): prop is string => typeof prop === 'string');

const concatCss = (cssProps: string[]) =>
  cssProps.length ? `${cssProps.join(';\n')};` : '';
export const cssProducer = pipe(removeFalseyValues, concatCss);

export type FlexPropertiesMixinProps = {
  alignItems?: string;
  alignSelf?: string;
  justifyContent?: string;
  flex?: string;
  flexDirection?: string;
  flexFlow?: string;
  flexWrap?: string;
  rowGap?: number;
  columnGap?: number;
};

export const FlexPropertiesMixin = css<FlexPropertiesMixinProps>`
  display: flex;
  ${({
    alignItems,
    justifyContent,
    flexDirection,
    flex,
    flexFlow,
    alignSelf,
    flexWrap,
    rowGap,
    columnGap,
  }) => {
    return cssProducer([
      alignItems !== undefined ? `align-items: ${alignItems}` : false,
      alignSelf !== undefined ? `align-self: ${alignSelf}` : false,
      justifyContent !== undefined
        ? `justify-content: ${justifyContent}`
        : false,
      flex !== undefined ? `flex: ${flex}` : false,
      flexDirection !== undefined ? `flex-direction: ${flexDirection}` : false,
      flexWrap !== undefined ? `flex-wrap: ${flexWrap}` : false,
      flexFlow !== undefined ? `flex: ${flexFlow}` : false,
      rowGap !== undefined ? `row-gap: ${rowGap}px` : false,
      columnGap !== undefined ? `column-gap: ${columnGap}px` : false,
    ]);
  }}
`;

export type SizingPropertiesMixinProps = {
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  minHeight?: string | number;
  minWidth?: string | number;
};
export const SizingPropertiesMixin = css<SizingPropertiesMixinProps>`
  ${({ width, height, maxWidth, maxHeight, minWidth, minHeight }) =>
    cssProducer([
      width !== undefined
        ? `width: ${width}${typeof width === 'number' ? 'px' : ''}`
        : false,
      height !== undefined
        ? `height: ${height}${typeof height === 'number' ? 'px' : ''}`
        : false,
      maxWidth !== undefined
        ? `max-width: ${maxWidth}${typeof maxWidth === 'number' ? 'px' : ''}`
        : false,
      maxHeight !== undefined
        ? `max-height: ${maxHeight}${typeof maxHeight === 'number' ? 'px' : ''}`
        : false,
      minHeight !== undefined
        ? `min-height: ${minHeight}${typeof minHeight === 'number' ? 'px' : ''}`
        : false,
      minWidth !== undefined
        ? `min-width: ${minWidth}${typeof minWidth === 'number' ? 'px' : ''}`
        : false,
    ])}
`;

export type PositionPropertiesMixinProps = {
  display?: string;
  position?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
};
export const PositionPropertiesMixin = css<PositionPropertiesMixinProps>`
  ${({ display, position, overflow, overflowX, overflowY }) =>
    cssProducer([
      display !== undefined ? `display: ${display}` : false,
      position !== undefined ? `position: ${position}` : false,
      overflow !== undefined ? `overflow: ${overflow}` : false,
      overflowX !== undefined ? `overflow-x: ${overflowX}` : false,
      overflowY !== undefined ? `overflow-y: ${overflowY}` : false,
    ])};
`;

const reduceBoxModelType = (
  space: number[] | DirectionInterface | number | string,
) => {
  if (typeof space === 'string') return space;
  if (Array.isArray(space)) {
    return space.map((num) => `${num}px`).join(' ');
  }
  if (typeof space === 'number') {
    return `${space}px`;
  }
  if (typeof space !== 'number') {
    return `${space.top || 0}px ${space.right || 0}px ${space.bottom || 0}px ${
      space.left || 0
    }px`;
  }
  return '0px';
};

export type BoxModelMixinProps = {
  padding?: number[] | DirectionInterface | number | string;
  margin?: number[] | DirectionInterface | number | string;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginBottom?: string | number;
  marginTop?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;
  paddingBottom?: string | number;
  paddingTop?: string | number;
  border?: string;
};
export const BoxModelMixin = css<BoxModelMixinProps>`
  ${({
    padding,
    margin,
    border,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  }) =>
    cssProducer([
      border !== undefined ? `border: ${border}` : false,
      padding !== undefined ? `padding: ${reduceBoxModelType(padding)}` : false,
      margin !== undefined ? `margin: ${reduceBoxModelType(margin)}` : false,
      paddingTop !== undefined
        ? `padding-top: ${reduceBoxModelType(paddingTop)}`
        : false,
      paddingRight !== undefined
        ? `padding-right: ${reduceBoxModelType(paddingRight)}`
        : false,
      paddingBottom !== undefined
        ? `padding-bottom: ${reduceBoxModelType(paddingBottom)}`
        : false,
      paddingLeft !== undefined
        ? `padding-left: ${reduceBoxModelType(paddingLeft)}`
        : false,
      marginTop !== undefined
        ? `margin-top: ${reduceBoxModelType(marginTop)}`
        : false,
      marginRight !== undefined
        ? `margin-right: ${reduceBoxModelType(marginRight)}`
        : false,
      marginBottom !== undefined
        ? `margin-bottom: ${reduceBoxModelType(marginBottom)}`
        : false,
      marginLeft !== undefined
        ? `margin-left: ${reduceBoxModelType(marginLeft)}`
        : false,
    ])};
`;

export type DivStyleMixinProps = {
  backgroundColor?: string;
  boxShadow?: string;
  opacity?: number;
};

export const DivStyleMixin = css<DivStyleMixinProps>`
  ${({ backgroundColor, boxShadow, opacity }) =>
    cssProducer([
      backgroundColor !== undefined
        ? `background-color: ${backgroundColor}`
        : false,
      boxShadow !== undefined ? `background-color: ${boxShadow}` : false,
      opacity !== undefined ? `opacity: ${opacity}` : false,
    ])}
`;

export type TextMixinProps = {
  color?: string;
  fontSize?: string;
  fontWeight?: string | number;
  textAlign?: string;
  textOverflow?: string;
  whiteSpace?: string;
  wordBreak?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textShadow?: string;
};
export const TextMixin = css<TextMixinProps>`
  ${({
    textAlign,
    textOverflow,
    whiteSpace,
    fontSize,
    color,
    wordBreak,
    fontWeight,
    lineHeight,
    letterSpacing,
    textShadow,
  }) =>
    cssProducer([
      textAlign !== undefined ? `text-align: ${textAlign}` : false,
      fontSize !== undefined ? `font-size: ${fontSize}` : false,
      textOverflow !== undefined ? `text-overflow: ${textOverflow}` : false,
      whiteSpace !== undefined ? `white-space: ${whiteSpace}` : false,
      color !== undefined ? `color: ${color}` : false,
      wordBreak !== undefined ? `word-break: ${wordBreak}` : false,
      fontWeight !== undefined ? `font-weight: ${fontWeight}` : false,
      lineHeight !== undefined ? `line-height: ${lineHeight}` : false,
      letterSpacing !== undefined ? `letter-spacing: ${letterSpacing}` : false,
      textShadow !== undefined ? `text-shadow: ${textShadow}` : false,
    ])}
`;
