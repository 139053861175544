import { PeriodType, WidgetMetricCategory } from '@amzn/claritygqllambda';
import { MetricWidgetRequiredSchema } from '@clarity-widgets/components/metric/MetricWidget';
import { ComputedSchema } from '@clarity-widgets/hooks/useGetSchema';
import { getOpsSubject } from '@clarity-widgets/hooks/view-utils';

export const MAX_USER_WIDGETS = 10;

export const Page = {
  Overview: 'Overview',
  Attendance: 'Attendance',
  Headcount: 'Headcount',
  Attrition: 'Attrition',
  OnPremise: 'On-Premise',
  STeam: 'S-team',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Page = (typeof Page)[keyof typeof Page];

export interface WidgetType {
  id: string;
  name: string;
  typePrefix: string;
}

// Enabling mapping of display names to backend widget suffixes
export const widgetTypes: WidgetType[] = [
  {
    id: '72bdb6f5-7944-4a62-be6d-5da211b5197f',
    name: 'Attendance',
    typePrefix: 'Attendance',
  },
  {
    id: '77680208-129f-4485-a2c3-c6841cd582c3',
    name: 'On premise',
    typePrefix: 'On_Premise',
  },
  {
    id: '94c0d952-78ee-40e5-a949-6559f52d111e',
    name: 'Headcount',
    typePrefix: 'Headcount',
  },
  {
    id: 'b9aaed64-64cf-4e69-a20c-6f9dd9a1cbf4',
    name: 'Attrition',
    typePrefix: 'Attrition',
  },
];

export enum TIMESPAN_CONSTANTS {
  'Last8Hours' = 'Last 8 Hours',
  'CurrentCalendarWeek' = 'Current Calendar Week',
  'LastCalendarWeek' = 'Last Calendar Week',
  'Last4Weeks' = 'Last 4 Weeks',
  'Last4Months' = 'Last 4 Months',
  'Last12Months' = 'Last 12 Months',
  'Last4Quarters' = 'Last 4 Quarters',
  'PerformanceYear' = 'Performance year',
  'All' = 'All',
}

export enum CURRENT_TIMESPAN_CONSTANTS {
  'CurrentDay' = 'Current Day',
  'CurrentWeekToDate' = 'Current WTD',
  'CurrentMonthToDate' = 'Current MTD',
  'CurrentQuarterToDate' = 'Current QTD',
}

export enum FeaturedMetric {
  ProductionAttendance = 'Production Attendance %',
  ProductionAttrition = 'Production Attrition %',
  ProductionHeadcount = 'Production Headcount',
  ProductionOnPremise = 'Production On Premise Headcount',
}

export const METRIC_CATEGORY_TO_FEATURED_METRIC: Record<
  WidgetMetricCategory,
  FeaturedMetric | null // #TBD
> = {
  [WidgetMetricCategory.Attendance]: FeaturedMetric.ProductionAttendance,
  [WidgetMetricCategory.Attrition]: FeaturedMetric.ProductionAttrition,
  [WidgetMetricCategory.Headcount]: FeaturedMetric.ProductionHeadcount,
  [WidgetMetricCategory.OnPremise]: FeaturedMetric.ProductionOnPremise,
  [WidgetMetricCategory.STeam]: null,
};

export const FEATURED_METRIC_TO_DESCRIPTION: Record<FeaturedMetric, string> = {
  [FeaturedMetric.ProductionAttendance]:
    'hours worked divided by scheduled hours for production associates',
  [FeaturedMetric.ProductionAttrition]:
    'count of terminations divided by headcount for production associates',
  [FeaturedMetric.ProductionHeadcount]:
    'the count of production associates assigned to a location',
  [FeaturedMetric.ProductionOnPremise]:
    'the count of production associates punched into a location but not punched out',
};

export const getOverviewDescription = (
  schema: MetricWidgetRequiredSchema,
  featuredMetric: string,
) => {
  const subject = getOpsSubject(schema);
  if (schema.hasMultipleSubjects) {
    return `Aggregated ${featuredMetric} for all your ${subject}s`;
  }
  return `${featuredMetric} for your ${subject}`;
};

export const TIMESPANS = [
  TIMESPAN_CONSTANTS.Last8Hours,
  TIMESPAN_CONSTANTS.CurrentCalendarWeek,
  TIMESPAN_CONSTANTS.LastCalendarWeek,
  TIMESPAN_CONSTANTS.Last4Weeks,
  TIMESPAN_CONSTANTS.Last4Months,
  TIMESPAN_CONSTANTS.Last4Quarters,
];

export const TIMESPAN_TEST_IDS = {
  [TIMESPAN_CONSTANTS.Last8Hours]: 'last-8-hours',
  [TIMESPAN_CONSTANTS.CurrentCalendarWeek]: 'current-calendar-week',
  [TIMESPAN_CONSTANTS.LastCalendarWeek]: 'last-calendar-week',
  [TIMESPAN_CONSTANTS.Last4Weeks]: 'last-4-weeks',
  [TIMESPAN_CONSTANTS.Last4Months]: 'last-4-months',
  [TIMESPAN_CONSTANTS.Last12Months]: 'last-12-months',
  [TIMESPAN_CONSTANTS.Last4Quarters]: 'last-4-quarters',
  [TIMESPAN_CONSTANTS.PerformanceYear]: 'performance-year',
  [TIMESPAN_CONSTANTS.All]: 'all',
};

export const getDetailedTooltipFromSchema = (
  schema: MetricWidgetRequiredSchema,
  featuredMetric: string,
) => {
  const widgetSubject = getOpsSubject(schema);
  if (schema.hasMultipleSubjects) {
    return `Compare ${featuredMetric} across ${widgetSubject}s or deep dive by selecting a single ${widgetSubject} from the chart to see related metrics.`;
  }
  return `Review ${featuredMetric} and related metrics for selected ${widgetSubject}.`;
};

export const S_TEAM_US_TOOLTIP =
  "This widget shows how your organization is performing on Amazon People KPI goals, tracking only L4+ headcount sitting in the United States, for each metric's specific goal period.";
export const S_TEAM_GLOBAL_TOOLTIP =
  "This widget shows how the organization is performing on Amazon People KPIs, for each metric's specific goal or time period.";
export const DIRECT_REPORT_OVERVIEW_TOOLTIP_CONTENT =
  'This widget shows how each of the direct reports, for the selected leader, are performing for all Amazon People KPIs. This view shows goal-period-to-date for TTR and URA, and last month for all others.';

export const TIMESPAN_COMPARISON_CONFIG: Record<TIMESPAN_CONSTANTS, string> = {
  [TIMESPAN_CONSTANTS.Last8Hours]: 'Last hour',
  [TIMESPAN_CONSTANTS.CurrentCalendarWeek]: '',
  [TIMESPAN_CONSTANTS.LastCalendarWeek]: '',
  [TIMESPAN_CONSTANTS.Last4Weeks]: '',
  [TIMESPAN_CONSTANTS.Last4Months]: 'Last month',
  [TIMESPAN_CONSTANTS.Last4Quarters]: 'Last quarter',
  [TIMESPAN_CONSTANTS.Last12Months]: '',
  [TIMESPAN_CONSTANTS.PerformanceYear]: '',
  [TIMESPAN_CONSTANTS.All]: '',
};

const OVERVIEW_TIMESPAN_SITE_COMPARISION_BYLINE: Record<
  TIMESPAN_CONSTANTS,
  string
> = {
  [TIMESPAN_CONSTANTS.Last8Hours]: 'for the last hour',
  [TIMESPAN_CONSTANTS.CurrentCalendarWeek]: '',
  [TIMESPAN_CONSTANTS.LastCalendarWeek]: '',
  [TIMESPAN_CONSTANTS.Last4Weeks]: '',
  [TIMESPAN_CONSTANTS.Last4Months]: 'for last month',
  [TIMESPAN_CONSTANTS.Last4Quarters]: 'for last quarter',
  [TIMESPAN_CONSTANTS.Last12Months]: '',
  [TIMESPAN_CONSTANTS.PerformanceYear]: '',
  [TIMESPAN_CONSTANTS.All]: '',
};

export const WIDGET_TIMESPAN_SITE_TO_PERIOD_TYPE: Record<
  TIMESPAN_CONSTANTS,
  PeriodType | undefined
> = {
  [TIMESPAN_CONSTANTS.Last8Hours]: PeriodType.Intraday,
  [TIMESPAN_CONSTANTS.CurrentCalendarWeek]: PeriodType.Weekly,
  [TIMESPAN_CONSTANTS.LastCalendarWeek]: PeriodType.Weekly,
  [TIMESPAN_CONSTANTS.Last4Weeks]: PeriodType.Weekly,
  [TIMESPAN_CONSTANTS.Last4Months]: PeriodType.Monthly,
  [TIMESPAN_CONSTANTS.Last4Quarters]: PeriodType.Quarterly,
  [TIMESPAN_CONSTANTS.Last12Months]: PeriodType.Monthly,
  [TIMESPAN_CONSTANTS.PerformanceYear]: PeriodType.Py,
  [TIMESPAN_CONSTANTS.All]: undefined,
};

export const NAVIGATION_CARD_VALUE_MAPPING: Record<
  string,
  CURRENT_TIMESPAN_CONSTANTS | TIMESPAN_CONSTANTS
> = {
  [TIMESPAN_CONSTANTS.Last8Hours]: TIMESPAN_CONSTANTS.Last8Hours,
  [TIMESPAN_CONSTANTS.CurrentCalendarWeek]:
    CURRENT_TIMESPAN_CONSTANTS.CurrentDay,
  [TIMESPAN_CONSTANTS.LastCalendarWeek]: TIMESPAN_CONSTANTS.LastCalendarWeek,
  [TIMESPAN_CONSTANTS.Last4Weeks]: TIMESPAN_CONSTANTS.Last4Weeks,
  [TIMESPAN_CONSTANTS.Last4Months]:
    CURRENT_TIMESPAN_CONSTANTS.CurrentMonthToDate,
  [TIMESPAN_CONSTANTS.Last4Quarters]:
    CURRENT_TIMESPAN_CONSTANTS.CurrentQuarterToDate,
  [TIMESPAN_CONSTANTS.All]: TIMESPAN_CONSTANTS.All,
};

const createComparisionBylines = (): Record<TIMESPAN_CONSTANTS, string> =>
  TIMESPANS.reduce<Record<TIMESPAN_CONSTANTS, string>>((acc, key) => {
    const comparision = `${OVERVIEW_TIMESPAN_SITE_COMPARISION_BYLINE[key]}`;
    acc[key] = comparision;
    return acc;
  }, {} as Record<TIMESPAN_CONSTANTS, string>);

export const getOverviewByline = (
  schema: ComputedSchema,
  activeTimespan: TIMESPAN_CONSTANTS,
  absoluteTimestamp = '',
) => {
  const pluralSuffix = schema.hasMultipleSubjects ? 's' : '';
  if (schema.hasSiteWidgets || schema.hasRegionWidgets) {
    const prefix = schema.hasSiteWidgets
      ? `Your site${pluralSuffix}`
      : `Your region${pluralSuffix}`;
    const relativeTimestamp = createComparisionBylines()[activeTimespan];

    return `${prefix} ${
      relativeTimestamp !== '' ? relativeTimestamp : `for ${absoluteTimestamp}`
    }`;
  }
};

export const getMetricCardCoachmarkText = (
  schema: MetricWidgetRequiredSchema | undefined,
) => {
  if (schema && schema.hasMultipleSubjects) {
    const subject = getOpsSubject(schema);
    return `Click a tab to see details by ${subject}`;
  }
  return `Click a tab to see related metrics`;
};

type MetricCard = {
  cardHeader: string;
  cardValue: string;
  page: Page;
  id: string;
  dataTestId: string;
};

export const OPS_TOP_CARD_CONFIG: MetricCard[] = [
  {
    cardHeader: 'All key metrics',
    cardValue: 'Overall',
    page: Page.Overview,
    id: 'Overall',
    dataTestId: 'overall-top-nav-card',
  },
  {
    cardHeader: WidgetMetricCategory.Attendance,
    cardValue: '',
    page: Page.Attendance,
    id: WidgetMetricCategory.Attendance,
    dataTestId: 'attendance-top-nav-card',
  },
  {
    cardHeader: WidgetMetricCategory.Attrition,
    cardValue: '',
    page: Page.Attrition,
    id: WidgetMetricCategory.Attrition,
    dataTestId: 'attrition-top-nav-card',
  },
  {
    cardHeader: 'Headcount',
    cardValue: '',
    page: Page.Headcount,
    id: WidgetMetricCategory.Headcount,
    dataTestId: 'headcount-top-nav-card',
  },
  {
    cardHeader: 'On Premise',
    cardValue: '',
    page: Page.OnPremise,
    id: WidgetMetricCategory.OnPremise,
    dataTestId: 'on-premise-top-nav-card',
  },
];

export const CORP_TOP_CARD_CONFIG: MetricCard[] = [
  {
    cardHeader: 'Amazon People KPIs',
    cardValue: '',
    page: Page.STeam,
    id: WidgetMetricCategory.STeam,
    dataTestId: 'amazon-people-kpis-top-nav-card',
  },
];

export const CLARITY_WIKI_URL =
  'https://w.amazon.com/bin/view/PeopleInsight/ProductCatalogue/Clarity';

export const CLARITY_WIKI_ACCESS_PAGE_URL =
  'https://w.amazon.com/bin/view/PeopleInsight/ProductCatalogue/Clarity/Access';

export const NO_ACCESS_SIM_TEMPLATE_URL =
  'https://t.corp.amazon.com/create/templates/eb02f901-26ff-44c9-a660-37f6508b3648';
