import {
  ReportTemplate as BackendReportTemplate,
  ColumnMetadata,
  DatasourceInfo,
  FilterV2,
  MetricStatusType,
  PivotedRowData,
  ReportGroupType,
  ReportType,
  RowDataV2,
  TimespanPair,
  ValueType,
} from '@amzn/claritygqllambda';
import { GoalSettingOutput } from '@amzn/claritygqllambda/dist/generated/graphql';
import { PivotTableHeader } from '@amzn/claritygqllambda/dist/packages/report-data/report-types';
import { record } from '@clarity-website/common/Analytics';
import { XRay } from '@clarity-website/common/XRay';
import { useTraceReportFetch } from '@clarity-website/common/useTraceReportFetch';
import { useXRay } from '@clarity-website/common/useXRay';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import { dynamicModalState } from '@clarity-website/reports/ReportPage/dynamicSelectionModalStore';
import { useCreateLeaderFilter } from '@clarity-website/reports/ReportPage/useDynamicLeaderSelection';
import { TimespanSchema } from '@clarity-website/reports/filters/timespan-config';
import {
  GoalColumnMetadata,
  ThresholdColors,
} from '@clarity-website/reports/goals/goalUtils';
import {
  ReportFilter,
  ReportGroupSheet,
} from '@clarity-website/reports/report-types';
import { decompressReportData } from '@clarity-website/reports/report-utils-data-transform';
import { DEFAULT_NEW_TAB_ID } from '@clarity-website/reports/tab/tab-util';
import { OutlierType } from '@clarity-website/science/odm/odm-types';
import {
  LatencyCategory,
  RecordLatencyData,
  useLogPerceivedLatency,
} from '@clarity-website/utils/useLogPerceivedLatency';
import {
  AccessStatus,
  ResultStatus,
  WidgetType,
} from '@clarity-widgets/widget-types';
import {
  QueryClient,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import addHours from 'date-fns/addHours';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { useAtomValue } from 'jotai';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';

const DATA_OUTPUT_VERSION = '2' as string;

export type PrimaryLeaderData = {
  isPrimaryLeader?: boolean;
  excludedLeadersCount?: number;
};

export type GoalData = {
  thresholdColor?: ThresholdColors;
  gapToGoal?: number;
};

export type OutlierData = {
  isOutlier?: boolean;
  aboveOrBelow?: OutlierType;
  upperBound?: number;
  lowerBound?: number;
};

export type Cell = {
  value: string;
  valueType: ValueType;
  displayValue?: string | React.ReactElement | null;
  comparisonValue?: number | null;
  upperBound?: number;
  lowerBound?: number;
  formattedDate?: string;
} & OutlierData &
  PrimaryLeaderData;

export type RowMetadata = {
  isSubtotalRow?: boolean;
  valueType?: ValueType;
  metricId?: string;
};

export type UnprocessedRow = {
  cells: (string | null)[];
  isSubtotalRow?: boolean;
  metricId?: string | undefined;
  valueType?: ValueType;
};

export type UnprocessedRawRowValues = {
  value: string;
  valueType: ValueType;
} & OutlierData &
  GoalData;

export type UnprocessedRawRow = {
  [key: string]: UnprocessedRawRowValues;
};

export type RawReportData = {
  id: string;
  errorMessage?: string | null;
  resultStatus: ResultStatus;
  updatedTime?: number;
  accessStatus?: AccessStatus;
  link?: string | null;
  rows: UnprocessedRawRow[];
  columnMetadata: ColumnMetadata[];
  athenaQueryEndTime: number;
  timespanTuples: TimespanPair[];
  datasourceInfoList?: DatasourceInfo[];
  reportModifiedTime: number;
  errorTitle?: string;
  waitTimeInSec?: number;
  goalColumns?: GoalColumnMetadata[];
  pivotedTableHeader?: PivotTableHeader[];
};

// I know this looks ugly but we know exactly what the differences are
export type ReportTemplate = Omit<
  BackendReportTemplate,
  | 'selectedFilters'
  | 'filtersV2'
  | 'selectedTimespanConfigs'
  | 'ownedBy'
  | 'reportVersion'
  | 'reportType'
  | 'createdTime'
  | 'updatedTime'
  | 'periodTypes'
  | 'widgetType'
  | 'description'
  | 'reportGroupId'
> & {
  filters: ReportFilter[];
  filtersV2?: string;
  selectedTimespans?: TimespanSchema[];
  reportType?: ReportType;
  createdTime?: string;
  updatedTime?: string;
  periodTypes?: string[];
  widgetType?: WidgetType;
  description: string;
  amazonKPIGoals?: GoalSettingOutput[];
};

export type ReportTemplateWithoutId = Omit<ReportTemplate, 'id'> & {
  id?: string;
};

export interface MetricStatus {
  name?: string;
  id: string;
  status: MetricStatusType;
  datasources: string[];
}

export type RawReportQueryType = {
  reportData: RawReportData;
  reportTemplate: ReportTemplate;
  metricStatuses: MetricStatus[];
};

type RawReportResponse = Omit<RawReportQueryType, 'reportData'> & {
  reportData: Omit<RawReportData, 'rows'> & {
    rows: UnprocessedRawRow[] | RowDataV2[] | PivotedRowData[];
  };
};

export type getDefaultLeaderInputType = {
  isLoading: boolean;
  isManager?: boolean;
  isLeader?: boolean;
  domain?: string;
};

const reportStaleTime = differenceInMilliseconds(
  addHours(new Date(), 1),
  new Date(),
);

const REQUEST_TIMEOUT = 60 * 1000;

export async function fetchReportData(
  traceId?: string,
  reportId?: string,
  dynamicFilters?: FilterV2[],
  canUseAdvancedPivoting?: boolean,
): Promise<RawReportResponse> {
  const shouldCallDynamicFilter =
    dynamicFilters?.length && dynamicFilters?.length > 0;
  const urlParams = new URLSearchParams();
  urlParams.append('outputVersion', DATA_OUTPUT_VERSION);

  if (canUseAdvancedPivoting) {
    urlParams.append('pivotData', DATA_OUTPUT_VERSION);
  }

  if (shouldCallDynamicFilter) {
    urlParams.append('dynamicFilter', JSON.stringify(dynamicFilters));
  }
  return new ClarityClient().rest<RawReportResponse>({
    path: `/raw-report-data/${reportId}?${urlParams.toString()}`,
    traceId,
    timeout: REQUEST_TIMEOUT,
  });
}

const FETCH_RAW_REPORT_SEGMENT_NAME = 'Frontend::FetchRawReport';
const PREFETCH_REPORT_TABS_SEGMENT_NAME = 'Frontend::PrefetchReportTabs';

export const reportQueryString = 'reportQuery';

const getReport = async (
  queryClient: QueryClient,
  queryKey: unknown[],
  traceId?: string,
  reportId?: string,
  dynamicFilters?: FilterV2[],
  canUseAdvancedPivoting?: boolean,
): Promise<RawReportQueryType> => {
  return fetchReportData(
    traceId,
    reportId,
    dynamicFilters,
    canUseAdvancedPivoting,
  ).then((res: RawReportResponse) => {
    const {
      reportData: { resultStatus },
    } = res;
    /**
     * Only record feedback when a report has loaded, or else we'll be spamming clickstream with too much data while long polling
     */
    if (ResultStatus.Pending !== resultStatus) {
      recordReportFeedback(res);
    }

    let result = res as RawReportQueryType;

    if (!canUseAdvancedPivoting && DATA_OUTPUT_VERSION === '2') {
      result = {
        ...res,
        reportData: {
          ...res.reportData,
          rows: decompressReportData(
            res.reportData.rows as RowDataV2[],
            res.reportData.columnMetadata,
          ),
        },
      };
    }

    return result;
  });
};

const recordReportFeedback = ({
  reportTemplate: { id, reportType, rootReportTemplateId, reportSubType },
  reportData: { resultStatus, errorMessage, rows },
}: RawReportResponse) => {
  record({
    name: 'report-data-feedback',
    attributes: {
      id,
      reportType,
      resultStatus,
      // for report too large errors the error message is over 200 characers in length
      // this exceeds the max size for this API resulting in the event failing to get logged
      // making sure the error message does not exceed 50 characters
      errorMessage: errorMessage?.slice(0, 50),
      rootReportTemplateId,
      reportSubType,
    },
    metrics: {
      numDataRows: rows.length,
    },
  });
};

interface UsePrefetchReportTabs {
  isFetched?: boolean;
  fetchingCount?: number;
  cancel: () => Promise<void>;
}

const defaultRefetchInterval = 1500;

export function usePrefetchReportTabs(
  sheets: { tableIds: string[] }[] = [],
  domains?: string[],
  reportGroupType?: ReportGroupType,
  cacheTime?: number,
): UsePrefetchReportTabs {
  const { beginSegment, endSegment } = useXRay();
  const queryClient = useQueryClient();
  const filters = useCreateLeaderFilter(domains, reportGroupType);

  const shouldFetchReport =
    reportGroupType &&
    (reportGroupType !== ReportGroupType.DynamicFilter || filters.length > 0);

  const queries = useQueries<RawReportQueryType[]>({
    queries: sheets
      .flatMap(({ tableIds }) => tableIds)
      .map((id) => ({
        enabled: shouldFetchReport,
        cacheTime: cacheTime ?? reportStaleTime,
        staleTime: reportStaleTime,
        queryKey: [reportQueryString, id, filters],
        refetchInterval: (data) => {
          const isPending =
            (data as RawReportQueryType | undefined)?.reportData
              .resultStatus === ResultStatus.Pending;
          return isPending ? defaultRefetchInterval : false;
        },
        queryFn: () => {
          const segment = beginSegment(
            PREFETCH_REPORT_TABS_SEGMENT_NAME,
            {
              request: {
                url: window.location.href,
                method: 'GET',
              },
            },
            {
              tabId: id,
            },
          );
          return getReport(
            queryClient,
            [reportQueryString, id, filters],
            XRay.getTraceHeader(segment),
            id,
            filters,
          ).then((data) => {
            endSegment(segment);
            return data;
          });
        },
      })),
  });

  const cancel = useCallback(async () => {
    await Promise.all(
      sheets
        .flatMap(({ tableIds }) => tableIds)
        .map((id) =>
          queryClient.cancelQueries([reportQueryString, id, filters]),
        ) ?? [Promise.resolve],
    );
  }, [sheets, filters, queryClient]);

  return {
    isFetched: queries.every(({ isFetched, data }) => {
      const queryData = data as RawReportQueryType | undefined;
      return (
        isFetched &&
        queryData &&
        queryData.reportData.resultStatus !== ResultStatus.Pending
      );
    }),
    fetchingCount: queries.filter(({ isFetching, data }) => {
      const queryData = data as RawReportQueryType | undefined;
      return (
        isFetching ||
        !queryData ||
        queryData.reportData.resultStatus === ResultStatus.Pending
      );
    }).length,
    cancel,
  };
}

export function useFetchRawReport({
  reportId,
  domains,
  reportGroupType,
  useSuspense,
  shouldRecordLatency = false,
  sheets,
  recordedTablesInSheet,
  canUseAdvancedPivoting,
}: {
  reportId?: string;
  domains?: string[];
  reportGroupType?: ReportGroupType;
  useSuspense?: boolean;
  shouldRecordLatency?: boolean;
  sheets?: ReportGroupSheet[];
  recordedTablesInSheet?: MutableRefObject<string[]>;
  canUseAdvancedPivoting?: boolean;
}) {
  const { segment, endSegment } = useTraceReportFetch(
    FETCH_RAW_REPORT_SEGMENT_NAME,
    reportId,
  );

  const [isUserIdle, setIsUserIdle] = useState(false);
  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'hidden') {
        setIsUserIdle(true);
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);
  const modalState = useAtomValue(dynamicModalState);

  const filters = useCreateLeaderFilter(
    domains,
    reportGroupType,
    modalState?.hasSelectedLeader,
  );

  const shouldFetchReport =
    !!reportId &&
    reportId !== DEFAULT_NEW_TAB_ID &&
    reportGroupType &&
    (reportGroupType !== ReportGroupType.DynamicFilter || filters.length > 0);
  const queryClient = useQueryClient();
  const cacheKey = [reportQueryString, reportId, filters];
  const [rateLimiterStatus, setRateLimiterStatus] = useState('NotRateLimited');

  const query = useQuery<RawReportQueryType>(
    cacheKey,
    () =>
      getReport(
        queryClient,
        cacheKey,
        XRay.getTraceHeader(segment),
        reportId,
        filters,
        canUseAdvancedPivoting,
      ),
    {
      refetchInterval: (data) => {
        if (data?.reportData.resultStatus === ResultStatus.RateLimitError) {
          setRateLimiterStatus('RateLimited');
          return data?.reportData.waitTimeInSec
            ? data?.reportData.waitTimeInSec * 1000
            : 0;
        }
        if (data?.reportData.resultStatus === ResultStatus.Pending) {
          return defaultRefetchInterval;
        }
        return false;
      },
      staleTime: reportStaleTime,
      enabled: !!shouldFetchReport,
      suspense: useSuspense || false,
    },
  );
  const reportSheet =
    reportId && sheets
      ? sheets.find((sheet) => sheet.tableIds.includes(reportId))
      : undefined;

  const firstRowSize = query?.data?.reportData?.rows?.length
    ? JSON.stringify(query?.data?.reportData?.rows[0]).length
    : 0;

  useLogPerceivedLatency({
    isFetchingData: query?.data?.reportData === undefined,
    setEndTimeCondition:
      !!query?.data?.reportData?.resultStatus &&
      query?.data?.reportData?.resultStatus !== ResultStatus.Pending &&
      query?.data?.reportData?.resultStatus !== ResultStatus.RateLimitError &&
      shouldRecordLatency,
    reportType: query?.data?.reportTemplate?.reportType,
    category: isUserIdle
      ? LatencyCategory.USER_NAVIGATED_AWAY_REPORT
      : LatencyCategory.REPORT,
    athenaQueryEndTime: query?.data?.reportData?.athenaQueryEndTime,
    rows: query?.data?.reportData?.rows?.length,
    timespanTuples: query?.data?.reportData?.timespanTuples?.length,
    traceId: XRay.getTraceHeader(segment),
    reportModifiedTime: query?.data?.reportData?.reportModifiedTime,
    resultStatus: query?.data?.reportData?.resultStatus,
    shouldFetchReport,
    reportId,
    sheet: reportSheet,
    recordedTablesInSheet,
    rateLimiterStatus,
    domain: query?.data?.reportTemplate?.domain,
    firstRowSize,
  } as RecordLatencyData);

  useEffect(() => {
    const status = query?.data?.reportData?.resultStatus;
    if (status && status !== ResultStatus.Pending) {
      endSegment();
    }
  }, [endSegment, query?.data?.reportData?.resultStatus]);

  return query;
}
