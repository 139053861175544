import {
  Domain,
  PeriodType,
  TimespanComparison,
  TimespanComparisonType,
} from '@amzn/claritygqllambda';

export enum DateType {
  Popular = 'Popular',
  Rolling = 'Rolling',
  Specific = 'Specific',
}

export enum TimeUnit {
  Hour = 'Hour',
  Hours = 'Hours',
  Day = 'Day',
  Days = 'Days',
  CalendarWeek = 'CalendarWeek',
  Weeks = 'Weeks',
  WeekToDate = 'WeekToDate',
  Months = 'Months',
  MonthToDate = 'MonthToDate',
  Ttm = 'Ttm',
  T3m = 'T3m',
  T6m = 'T6m',
  Quarters = 'Quarters',
  QuarterToDate = 'QuarterToDate',
  Years = 'Years',
  YearToDate = 'YearToDate',
  PerformanceYears = 'PerformanceYears',
  PerformanceYearToDate = 'PerformanceYearToDate',
  PerformanceMonths = 'PerformanceMonths',
  PerformanceMonthToDate = 'PerformanceMonthToDate',
  PerformanceQuarters = 'PerformanceQuarters',
  PerformanceQuarterToDate = 'PerformanceQuarterToDate',
}

export enum TimePresence {
  Last = 'Last',
  Current = 'Current',
  Next = 'Next',
  Range = 'Range',
}

export enum PeriodAnchor {
  Begin = 'Begin',
  End = 'End',
}

export enum TimeAnchor {
  Today = 'as of today',
  CurrentYear = 'as of current year',
  Month = 'as of previous month',
  Quarter = 'as of previous quarter',
  ThreeMonths = 'as of 3 months prior',
  SixMonths = 'as of 6 months prior',
  Year = 'as of prior year',
  TwoYears = 'as of 2 years prior',
}

export type AbsoluteTimespanJson = {
  [key in Domain]: {
    [period in PeriodType]: string[];
  };
};

export type InputError = {
  isError: boolean;
  message?: string;
  displayAsWarning?: boolean;
};

export type RollingTimespanData = {
  timeUnit: TimeUnit;
  timePresence: TimePresence;
  value?: number;
  anchor: TimeAnchor;
  comparisons?: TimespanComparison[];
};

export type SpecificTimespanData = {
  timeUnit: TimeUnit;
  start: Date | undefined;
  end: Date | undefined;
  comparisons: TimespanComparison[];
};

export type DescriptionText = {
  description: string;
  hasToolTipText: boolean;
  tooltip?: string;
};

export type AdjusterFunctionData = {
  name: string;
  duration?: number;
};

export type PopMetadata = {
  displayValue: string;
  postFix: string;
  fullDisplayValue: string;
};

export type ComparisonsState = {
  [key: string]: { isActive: boolean; comparisonType?: TimespanComparisonType };
};
