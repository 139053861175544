import { Workbox } from 'workbox-window';
import { WorkboxLifecycleWaitingEvent } from 'workbox-window/utils/WorkboxEvent';
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

type Config = {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  immediate?: boolean;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
    const doRegister = () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        console.info('localhost?');
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA',
          );
        });
      } else {
        console.info('registering valid sw', swUrl);
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    };

    if (config && config.immediate) {
      doRegister();
    } else {
      window.addEventListener('load', doRegister);
    }
  }
}

let refreshing = false;
const reloadPage = () => {
  if (refreshing) return;
  window.location.reload();
  refreshing = true;
};

let registration: ServiceWorkerRegistration | undefined;
const getRegistration = async (): Promise<
  ServiceWorkerRegistration | undefined
> => {
  return registration || navigator.serviceWorker.getRegistration();
};

function registerValidSW(swUrl: string, config?: Config) {
  const wb = new Workbox(swUrl);

  const showUpdateToUser = (_event: WorkboxLifecycleWaitingEvent) => {
    getRegistration().then((reg) => {
      if (reg) {
        config?.onUpdate?.(reg);
      }
    });
  };

  // SW pings S3 for its manifest, and does a byte by byte comparison
  // to see if anything has changed
  const checkServerForUpdate = () =>
    getRegistration().then((reg) => reg?.update());

  // registration.update() will check S3 for updates to the app
  if (config?.immediate) {
    checkServerForUpdate();
  }

  // If a service worker in another tab takes over, take over this page too.
  navigator.serviceWorker.addEventListener('controllerchange', reloadPage);

  // This event fires after the SkipWaiting event has been called by the developer
  wb.addEventListener('controlling', reloadPage);

  // This event fires when a SW is stuck in the 'waiting' phase.
  wb.addEventListener('waiting', showUpdateToUser);

  // register the service worker, and put in in scope for these listeners.
  wb.register().then((reg) => {
    registration = reg;
  });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
