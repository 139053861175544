import {
  DatasourceInfo,
  DynamicNumericComparators,
  PeriodType,
  TimespanPair,
  ValueType,
} from '@amzn/claritygqllambda';
import {
  GoalInput,
  GoalSettingOutput,
  GoalThreshold,
  Maybe,
  MetricGoalMap,
} from '@amzn/claritygqllambda/dist/generated/graphql';
import Filter from '@amzn/claritygqllambda/dist/packages/report-template/model/Filter';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { TimespanSchema } from '@clarity-website/exports/timespan';
import { MaintenanceMetric } from '@clarity-website/maintenance/form/metrics/EditItemMetricCore';
import Message from '@clarity-website/message-banners/Message';
import {
  DEFAULT_SUCCESS_AUTO_DISMISS_AFTER_SEC,
  MessageGroupIds,
} from '@clarity-website/message-banners/MessageGroupContainer';
import { getPeriodTypeFromRawTimespan } from '@clarity-website/react-table-data-handler/common/utils';
import { reportQueryString } from '@clarity-website/reports//useFetchRawReport';
import { ReportTemplatesByKey } from '@clarity-website/reports/ReportPage/useGetReportTemplate';
import { GOAL_COLUMNS } from '@clarity-website/reports/edit/timespan/constants';
import { MetricsInterface } from '@clarity-website/reports/filters/filter-types';
import { FilterState } from '@clarity-website/reports/filters/filterReducerTypes';
import {
  TOP_TIER_RETENTION,
  UNREGRETTED_ATTRITION,
} from '@clarity-widgets/widget-constants';
import parse from 'date-fns/parse';
import { equals } from 'ramda';

export enum ThresholdColors {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export const AMAZON_KPI_GOALS = 'AmazonKPIGoal';

export const isProjectedGoalMetric = (metricId: string) =>
  [TOP_TIER_RETENTION, UNREGRETTED_ATTRITION].includes(metricId);

enum PriorityOrder {
  'GREEN',
  'RED',
  'YELLOW',
}

const OrderedGoalTimepsans = [
  'intradaySql',
  'dailySql',
  'weeklySql',
  'monthlySql',
  'quarterlySql',
  'yearlySql',
  'pmSql',
  'pqSql',
  'pySql',
  'ttmSql',
  't3mSql',
  't6mSql',
] as const;

// For creating new goals, we need to check the metric for applicable timespans by chcking sql
// When loading created goals, we have to covert PeriodTypes to strings
export const goalTimespanNames: { [key: string]: string } = {
  intradaySql: 'Hourly',
  dailySql: 'Daily',
  weeklySql: 'Weekly',
  monthlySql: 'Monthly',
  quarterlySql: 'Quarterly',
  yearlySql: 'Yearly',
  pmSql: 'Performance Month',
  pqSql: 'Performance Quarter',
  pySql: 'Performance Year',
  t3mSql: 'T3M',
  t6mSql: 'T6M',
  ttmSql: 'TTM',
  [PeriodType.Intraday]: 'Hourly',
  [PeriodType.Daily]: 'Daily',
  [PeriodType.Weekly]: 'Weekly',
  [PeriodType.Monthly]: 'Monthly',
  [PeriodType.Quarterly]: 'Quarterly',
  [PeriodType.Yearly]: 'Yearly',
  [PeriodType.Pm]: 'Performance Month',
  [PeriodType.Pq]: 'Performance Quarter',
  [PeriodType.Py]: 'Performance Year',
  [PeriodType.T3m]: 'T3M',
  [PeriodType.T6m]: 'T6M',
  [PeriodType.Ttm]: 'TTM',
};

// goals are saved to the PeriodType and not the displayed name
export const goalTimespanNamesToPeriodType: { [key: string]: PeriodType } = {
  Hourly: PeriodType.Intraday,
  Daily: PeriodType.Daily,
  Weekly: PeriodType.Weekly,
  Monthly: PeriodType.Monthly,
  Quarterly: PeriodType.Quarterly,
  Yearly: PeriodType.Yearly,
  'Performance Month': PeriodType.Pm,
  'Performance Quarter': PeriodType.Pq,
  'Performance Year': PeriodType.Py,
  T3M: PeriodType.T3m,
  T6M: PeriodType.T6m,
  TTM: PeriodType.Ttm,
};

function getTimespansForMetric<T>(
  valueBySqlType: { [key: string]: T },
  metric: MaintenanceMetric,
) {
  return OrderedGoalTimepsans.reduce((acc: T[], sqlType) => {
    (metric?.[sqlType] || []).length && acc.push(valueBySqlType[sqlType]);
    return acc;
  }, []);
}

export function getTimespansForMetricInterface<T>(
  valueBySqlType: { [key: string]: T },
  metric: MetricsInterface,
) {
  return OrderedGoalTimepsans.reduce((acc: T[], sqlType) => {
    (metric?.[sqlType] || []).length && acc.push(valueBySqlType[sqlType]);
    return acc;
  }, []);
}

export const getCompatibleGoalTimespans = (metric: MaintenanceMetric) =>
  getTimespansForMetric(goalTimespanNames, metric);

export interface ProcessedGoalThresholdForTimespan {
  [timespan: string]: Array<GoalThreshold | null>;
}

export interface UpdateGoalsPayload {
  metricId: string;
  goalId: string;
  shouldDelete: boolean;
  metricGoal?: GoalInput;
}

/**
 * ProcessedGoals
 *  {
 *    metricId1: {
 *      goalId1: {
 *        goalType: 'dynamic',
 *        function: 'AnnualToMonthlyDecreaseInterpolator',
 *        name: 'dynamic goal name',
 *        goals: {
 *          Daily: [],
 *          Weekly: [],
 *          ...
 *        }
 *      },
 *      goalId2: {
 *        goalType: 'static',
 *        name: 'goal 2',
 *        goals: {
 *          Ttm: [],
 *          Yearly: [],
 *          ...
 *        }
 *      },
 *    },
 *    metricId2: {
 *      goalId3: {
 *        goalType: 'static',
 *        goals: {
 *          Monthly: [],
 *          T3m: [],
 *          ...
 *        }
 *      },
 *    },
 *  }
 */
export interface ProcessedGoals {
  [metricId: string]: {
    [goalId: string]: {
      goalType: string;
      function?: string | null;
      name?: string;
      goals: ProcessedGoalThresholdForTimespan;
    };
  };
}

export type GoalThresholdSignMap = {
  goalThreshold: GoalThreshold | null;
  hasSameOperationSign: boolean;
};

const positiveSignMap = [
  DynamicNumericComparators.GreaterThan,
  DynamicNumericComparators.GreaterThanEqual,
];

export const processRawGoalData = (
  rawGoals: GoalSettingOutput[],
): ProcessedGoals => {
  return rawGoals.reduce<ProcessedGoals>(
    (acc, { metricId, goalId, goals, ...goal }: GoalSettingOutput) => {
      acc[metricId] = {
        ...acc[metricId],
        [goalId]: {
          ...goal,
          goals: goals.reduce<ProcessedGoalThresholdForTimespan>(
            (thresholdAcc, threshold) => {
              const { timespan, thresholds } = threshold || {};
              if (timespan && thresholds) {
                thresholdAcc[timespan] = thresholds;
              }
              return thresholdAcc;
            },
            {},
          ),
        },
      };
      return acc;
    },
    {},
  );
};

function compareValues(goalThreshold: GoalThreshold, actualValue: number) {
  const { numericComparator, value: threshold } = goalThreshold;
  switch (numericComparator) {
    case DynamicNumericComparators.GreaterThan:
      return actualValue > threshold;
    case DynamicNumericComparators.GreaterThanEqual:
      return actualValue >= threshold;
    case DynamicNumericComparators.LessThanEqual:
      return actualValue <= threshold;
    case DynamicNumericComparators.LessThan:
      return actualValue < threshold;
    default:
      return false;
  }
}

/**
 *
 * @param  {GoalThresholdSignMap} goalThresholdMap the goal threshold with which we need to compare the metric value
 * @param {number} actualValue the actual value of the metric
 * @returns if green goal is set such that x > 1 , then it returns 10bps for metricValue = 1.1, and -10bps for 0.9
 * if green goal is not set and red goal is set such that x > 1, then it returns 10bps for metricValue = 0.9, and -10bps for 1.1
 */
export function getGapToGoalBps(
  goalThresholdMap: GoalThresholdSignMap | undefined,
  actualValue: number,
) {
  if (goalThresholdMap && goalThresholdMap.goalThreshold) {
    const { goalThreshold, hasSameOperationSign } = goalThresholdMap;
    const { numericComparator, value } = goalThreshold;
    const multiplier = hasSameOperationSign ? 1 : -1;
    const signMultiplier = positiveSignMap.some(
      (operator) => operator === numericComparator,
    )
      ? 1
      : -1;
    return (
      (multiplier * signMultiplier * (actualValue - value) * 100)
        .toFixed(2)
        .replace('.00', '') + 'bps'
    );
  }
}

/*
 * There is no validation to make ensure the user does not have conflicting goals
 * so return the first valid goal with the priority being green > red > yellow
 */
export function getMetricGoalThresholdBreach(
  goalThresholds: Array<GoalThreshold | null>,
  actualValue: number,
  valueType: ValueType,
) {
  const metricValue =
    valueType === ValueType.Percentage
      ? Number((actualValue * 100).toFixed(2))
      : actualValue;
  const updatedGoalThresholdMap =
    updateGoalThresholdsPriorityOrderSignMap(goalThresholds);

  const goalThresholdPrioritySignMap = updatedGoalThresholdMap.find(
    (goalThresholdMap) =>
      !!goalThresholdMap.goalThreshold &&
      goalThresholdMap.goalThreshold !== null,
  );

  const goalThresholdIdx = updatedGoalThresholdMap.findIndex(
    (goalThresholdMap) =>
      goalThresholdMap.goalThreshold &&
      compareValues(goalThresholdMap.goalThreshold, metricValue),
  );

  if (goalThresholdIdx >= 0 && goalThresholdPrioritySignMap !== null) {
    return {
      thresholdColor: PriorityOrder[goalThresholdIdx],
      gapToGoal: getGapToGoalBps(goalThresholdPrioritySignMap, metricValue),
      goalTarget: goalColumnValue(goalThresholdPrioritySignMap, valueType),
    };
  }
  return {
    gapToGoal: getGapToGoalBps(goalThresholdPrioritySignMap, metricValue),
    goalTarget: goalColumnValue(goalThresholdPrioritySignMap, valueType),
  };
}

export type GoalColumnMetadata = {
  id: string;
  title?: string;
  groupingAttribute?: boolean;
  timespanList: [PeriodType];
  valueType?: ValueType;
  metricId: string;
  sortKey?: string;
};
export interface GoalColMap {
  [key: string]: GoalColumnMetadata;
}

export interface RawTimespanToPeriodTypeMapping {
  [key: string]: PeriodType;
}

export type CurrentTimespanMetadata = {
  isPresent: boolean;
  lastUpdated?: string;
};

export interface RawTimespanToCurrentTimespanMapping {
  [key: string]: CurrentTimespanMetadata;
}

export type GoalModifiedMetricType =
  | 'goalUpdatedMetrics'
  | 'goalCreatedMetrics'
  | 'goalDeletedMetrics';
export type ModifiedGoalMetricsMap = Record<GoalModifiedMetricType, string[]>;
// create one mapping of AbsoluteTimespan to PeriodType
// to identify which goals to apply to each row
/**
 * example:
 *  {
 *    "m_20230401": "Monthly",
 *    "w_20230820_34": "Weekly"
 *  }
 */
export const reportTimespanToPeriodTypeMapping = (
  timespanTuples: TimespanPair[],
) => {
  return timespanTuples.reduce<RawTimespanToPeriodTypeMapping>((acc, tuple) => {
    const periodType: PeriodType = getPeriodTypeFromRawTimespan(
      tuple.absoluteTimespan,
    );
    if (periodType) {
      acc[tuple.absoluteTimespan] = periodType;
    }
    return acc;
  }, {});
};

export function getActiveMetricAndProcessedGoalMap(
  selectedMetricIds: string[],
  selectedGoals?: MetricGoalMap[],
  reportGoals?: GoalSettingOutput[],
) {
  const activeMetricGoals = selectedGoals?.filter(
    (goal) => goal.isActive && selectedMetricIds.includes(goal.metricId),
  );

  const processedGoalMap = processRawGoalData(reportGoals || []);

  const activeMetricGoalMap = activeMetricGoals?.filter(
    ({ goalId, metricId }) => processedGoalMap?.[metricId]?.[goalId],
  );
  return { activeMetricGoalMap, processedGoalMap };
}

export enum ComparatorMap {
  GreaterThan = '>',
  GreaterThanEqual = '≥',
  LessThanEqual = '≤',
  LessThan = '<',
}

export enum ComparatorTextMap {
  GreaterThan = 'Greater than',
  GreaterThanEqual = 'Greater than or equal to',
  LessThanEqual = 'Less than or equal to',
  LessThan = 'Less than',
}

export const comparatorSignMap: Record<string, string> = {
  [DynamicNumericComparators.GreaterThan]: ComparatorMap.GreaterThan,
  [DynamicNumericComparators.GreaterThanEqual]: ComparatorMap.GreaterThanEqual,
  [DynamicNumericComparators.LessThanEqual]: ComparatorMap.LessThanEqual,
  [DynamicNumericComparators.LessThan]: ComparatorMap.LessThan,
};

export const reverseComparatorSignMap: Record<string, string> = {
  [ComparatorMap.GreaterThan]: DynamicNumericComparators.GreaterThan,
  [ComparatorMap.GreaterThanEqual]: DynamicNumericComparators.GreaterThanEqual,
  [ComparatorMap.LessThanEqual]: DynamicNumericComparators.LessThanEqual,
  [ComparatorMap.LessThan]: DynamicNumericComparators.LessThan,
};

export const comparatorOppositeSignMap: Record<string, string> = {
  [DynamicNumericComparators.GreaterThan]: ComparatorMap.LessThanEqual,
  [DynamicNumericComparators.GreaterThanEqual]: ComparatorMap.LessThan,
  [DynamicNumericComparators.LessThanEqual]: ComparatorMap.GreaterThan,
  [DynamicNumericComparators.LessThan]: ComparatorMap.GreaterThanEqual,
};

export const comparatorTextMap: Record<string, string> = {
  [DynamicNumericComparators.GreaterThan]: ComparatorTextMap.GreaterThan,
  [DynamicNumericComparators.GreaterThanEqual]:
    ComparatorTextMap.GreaterThanEqual,
  [DynamicNumericComparators.LessThanEqual]: ComparatorTextMap.LessThanEqual,
  [DynamicNumericComparators.LessThan]: ComparatorTextMap.LessThan,
};

export const comparatorInputDisplayMap: Record<string, string> = {
  [`${ComparatorMap.GreaterThan} ${ComparatorTextMap.GreaterThan}`]:
    ComparatorMap.GreaterThan,
  [`${ComparatorMap.GreaterThanEqual} ${ComparatorTextMap.GreaterThanEqual}`]:
    ComparatorMap.GreaterThanEqual,
  [`${ComparatorMap.LessThanEqual} ${ComparatorTextMap.LessThanEqual}`]:
    ComparatorMap.LessThanEqual,
  [`${ComparatorMap.LessThan} ${ComparatorTextMap.LessThan}`]:
    ComparatorMap.LessThan,
};

export const isPerformancePeriodType = (timespanType: PeriodType) =>
  [PeriodType.Pm, PeriodType.Pq, PeriodType.Py].includes(timespanType);

export function performancePeriodTypeLastRefreshDates(
  datasource: DatasourceInfo,
): Record<string, number | undefined> {
  return {
    [PeriodType.Pm]: datasource.lastAsOfDatePm,
    [PeriodType.Py]: datasource.lastAsOfDatePy,
    [PeriodType.Pq]: datasource.lastAsOfDatePq,
  };
}

function goalColumnValue(
  goalThresholdMap: GoalThresholdSignMap | undefined,
  valueType: ValueType,
) {
  if (goalThresholdMap && goalThresholdMap.goalThreshold) {
    const { goalThreshold, hasSameOperationSign } = goalThresholdMap;
    const { numericComparator, value } = goalThreshold;
    return `${
      hasSameOperationSign
        ? comparatorSignMap[numericComparator]
        : comparatorOppositeSignMap[numericComparator]
    } ${valueType === ValueType.Percentage ? value + '%' : value}`;
  }
}

export function updateGoalThresholdsPriorityOrderSignMap(
  goalThresholds: Array<Maybe<GoalThreshold>>,
): GoalThresholdSignMap[] {
  return [
    { goalThreshold: goalThresholds[0], hasSameOperationSign: true },
    { goalThreshold: goalThresholds[2], hasSameOperationSign: false },
    { goalThreshold: goalThresholds[1], hasSameOperationSign: false },
  ];
}

export function getMetricGoalColumns(
  colId: string,
  hasDeltaColumn: boolean,
  hasGoalColumn: boolean,
) {
  const metricAndGoalIds = [colId];
  if (hasGoalColumn) {
    metricAndGoalIds.push(`${colId}${GOAL_COLUMNS.goalTarget.columnSuffix}`);
  }

  if (hasDeltaColumn) {
    metricAndGoalIds.push(`${colId}${GOAL_COLUMNS.deltaColumn.columnSuffix}`);
  }

  return metricAndGoalIds;
}

export function getMetricSelectedGoalsMap(
  modifiedMetricGoalMap: Map<string, MetricGoalMap>,
  selectedGoals?: Map<string, MetricGoalMap>,
): Map<string, MetricGoalMap> {
  return new Map([
    ...(selectedGoals?.entries() || new Map()),
    ...modifiedMetricGoalMap.entries(),
  ]);
}

export function getCurrentTimespanInfoMap(
  timespanTuples: TimespanPair[],
  selectedTimespans: TimespanSchema[],
  lastTimespanUpdateTimeMap: Record<string, number | undefined>,
  timespanToPeriodTypeMapping: RawTimespanToPeriodTypeMapping,
): RawTimespanToCurrentTimespanMapping {
  return timespanTuples.reduce<RawTimespanToCurrentTimespanMapping>(
    (acc, { absoluteTimespan, timespanIndex }) => {
      const { temporalAdjusters } = selectedTimespans[timespanIndex];
      if (temporalAdjusters) {
        const periodType = timespanToPeriodTypeMapping[absoluteTimespan];
        acc[absoluteTimespan] = {
          isPresent: !temporalAdjusters.find((adjuster) =>
            adjuster.function.startsWith('minus'),
          ),
          lastUpdated: lastTimespanUpdateTimeMap[periodType]?.toString() || '',
        };
      }
      return acc;
    },
    {},
  );
}
const parseTimespan = (
  timespan: string,
  { isPresent, lastUpdated }: CurrentTimespanMetadata,
) => {
  // For Current Performance Year (YTD) only return the projected calculation
  // for the latest month if the last updated date is available
  if (timespan.startsWith('py') && isPresent && lastUpdated) {
    const performanceYearMonth = parse(lastUpdated, 't', new Date());

    return performanceYearMonth.getMonth();
  }

  // For Peformance Quarter, the timespan looks like pq_20240401_2
  // which is the start of Q2. Projections are for the end of a quarter
  // so 2 months are added
  if (timespan.startsWith('pq')) {
    const performanceQuarterMonth = parse(
      timespan.split('_')[1],
      'yyyyMMdd',
      new Date(),
    );

    return performanceQuarterMonth.getMonth() + 2;
  }

  // This works for performance month (both current and last),
  // and last performance year (timespan is PY end)
  const defaultMonth = parse(
    timespan.slice(timespan.indexOf('_') + 1),
    'yyyyMMdd',
    new Date(),
  );
  return defaultMonth.getMonth();
};

// projected performance calculations based on calendar month are offset by 3
const performanceYearMonths = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const extractPerformanceMonthNumberFromTimespan = (
  timespan: string,
  currentTimespanInfo: CurrentTimespanMetadata,
) => {
  const month = parseTimespan(timespan, currentTimespanInfo);
  return performanceYearMonths[month];
};

export function calculateProjectedGoalValueForPerformanceTimespan(
  metricId: string,
  timespan: string,
  currentTimespanInfo: CurrentTimespanMetadata,
  goalThresholds: (GoalThreshold | null)[],
): (GoalThreshold | null)[] {
  const timespanMonth = extractPerformanceMonthNumberFromTimespan(
    timespan,
    currentTimespanInfo,
  );
  if (metricId === UNREGRETTED_ATTRITION) {
    return goalThresholds.map((threshold) => {
      if (threshold) {
        const projectedThreshold = {
          ...threshold,
          value: Number((timespanMonth * (threshold.value / 12)).toFixed(2)),
        };

        return projectedThreshold;
      }
      return null;
    });
  } else if (metricId === TOP_TIER_RETENTION) {
    return goalThresholds.map((threshold) => {
      if (threshold) {
        const projectedThreshold = {
          ...threshold,
          value: Number(
            (100 - timespanMonth * ((100 - threshold.value) / 12)).toFixed(2),
          ),
        };

        return projectedThreshold;
      }
      return null;
    });
  }
  return goalThresholds;
}

// need to include amazonKPI Goals too.
export function getReportGoalsList(
  reportGoals?: GoalSettingOutput[],
  setGoalsPayloadMap?: Map<string, GoalInput>,
  deleteGoalsPayloadMap?: Map<string, string[]>,
): GoalInput[] {
  const deleteGoalIds = Array.from(
    deleteGoalsPayloadMap?.values() || [],
  ).flat();
  return [
    reportGoals?.filter(
      ({ goalId }) =>
        !deleteGoalIds.includes(goalId) && !setGoalsPayloadMap?.has(goalId),
    ) || [],
    Array.from(setGoalsPayloadMap?.values() || []),
  ].flat();
}

export function hasMetricGoalTogglesUpdated(
  appliedSelectedGoalsMetricMap: Map<string, MetricGoalMap>,
  pristineSelectedGoals?: Map<string, MetricGoalMap>,
) {
  return (
    getGoalToggleUpdatedMetrics(
      appliedSelectedGoalsMetricMap,
      pristineSelectedGoals,
    ).length > 0
  );
}

export function getGoalToggleUpdatedMetrics(
  appliedSelectedGoalsMetricMap: Map<string, MetricGoalMap>,
  pristineSelectedGoals?: Map<string, MetricGoalMap>,
) {
  return Array.from(appliedSelectedGoalsMetricMap.entries()).reduce<string[]>(
    (acc, [metricId, metricGoalMap]) => {
      const { goalId, __typename, ...modifiedMetricGoalProps } = metricGoalMap;
      const pristineMetricGoalMap = pristineSelectedGoals?.get(metricId) || {
        goalId,
        metricId,
        isActive: false,
        showDeltaColumn: true,
        showGoalColumn: true,
      };

      const {
        goalId: initGoalId,
        __typename: _,
        ...unModifiedMetricGoalProps
      } = pristineMetricGoalMap;

      // One of the conditions needs to be true:
      // 1) IsActive true, but all remaining props can be changed
      // {  !equals(modifiedMetricGoalProps, unModifiedMetricGoalProps) || (metricGoalMap.isActive && goalId !== initGoalId)}
      // 2) IsActive false, then show delta or goal column can be changed and change to goalId doesn't matter
      // {!equals(modifiedMetricGoalProps, unModifiedMetricGoalProps)}
      // 3) IsActive flag changed but all props remained same
      // {!equals(modifiedMetricGoalProps, unModifiedMetricGoalProps)}
      if (
        !equals(modifiedMetricGoalProps, unModifiedMetricGoalProps) ||
        (metricGoalMap.isActive && goalId !== initGoalId)
      ) {
        acc = [...acc, metricId];
      }
      return acc;
    },
    [],
  );
}

export function hasGoalsSetForTheTimespan(
  goalThreshold: (GoalThreshold | null)[],
) {
  return goalThreshold.some((goalValue) => !!goalValue);
}

export function getGoalCreatedAndUpdatedMetrics(
  setGoalsPayloadMap: Map<string, GoalInput>,
  pristineReportGoals?: GoalSettingOutput[],
) {
  const goalIdReportGoalsMap: Record<string, GoalInput> =
    pristineReportGoals?.reduce((acc, reportGoal) => {
      return { ...acc, [reportGoal.goalId]: reportGoal as GoalInput };
    }, {}) || {};

  const goalUpdatedMetrics = new Set<string>();
  const goalCreatedMetrics = new Set<string>();

  Array.from(setGoalsPayloadMap.entries()).forEach(([goalId, goal]) => {
    const pristineGoal = goalIdReportGoalsMap[goalId];
    const { metricId } = goal;
    if (pristineGoal && !equals(pristineGoal, goal)) {
      goalUpdatedMetrics.add(metricId);
    } else if (!pristineGoal) {
      goalCreatedMetrics.add(metricId);
    }
  });

  return {
    goalCreatedMetrics: Array.from(goalCreatedMetrics),
    goalUpdatedMetrics: Array.from(goalUpdatedMetrics),
  };
}

export function getGoalModifiedBannerMessage(
  key: GoalModifiedMetricType,
  metricNames: string[],
) {
  const concatMetricNames = metricNames.join();
  switch (key) {
    case 'goalUpdatedMetrics':
      return `Goals updated for ${concatMetricNames}`;
    case 'goalCreatedMetrics':
      return `Goals set for ${concatMetricNames}`;
    case 'goalDeletedMetrics':
      return `Goals deleted for ${concatMetricNames}`;
  }
}

export function getModifiedGoalMetricsMap(
  filterState: FilterState,
  availMet?: MetricsInterface[],
): ModifiedGoalMetricsMap {
  const updatedGoalMetrics = getGoalCreatedAndUpdatedMetrics(
    filterState.metrics.setGoalsPayloadMap,
    filterState.metrics.pristineReportGoals,
  );

  const goalDeletedMetrics = Array.from(
    filterState.metrics.deleteGoalsPayloadMap.keys(),
  );

  let modifiedGoalMetricsMap = {
    goalCreatedMetrics: updatedGoalMetrics.goalCreatedMetrics,
    goalUpdatedMetrics: updatedGoalMetrics.goalUpdatedMetrics,
    goalDeletedMetrics,
  };

  Object.entries(modifiedGoalMetricsMap).forEach(([key, values]) => {
    const mapKey = key as GoalModifiedMetricType;
    modifiedGoalMetricsMap[mapKey] = values.map(
      (metricId) =>
        availMet?.find((metric) => metric.id === metricId)?.name || metricId,
    );
  });
  return modifiedGoalMetricsMap;
}

export function addMessageForMetricGoalChanges(
  modifiedGoalMetricsMap: ModifiedGoalMetricsMap,
  addMessage: (message: Message) => string,
) {
  if (modifiedGoalMetricsMap) {
    Object.entries(modifiedGoalMetricsMap).forEach(([key, values]) => {
      if (values.length > 0) {
        const bannerText = getGoalModifiedBannerMessage(
          key as GoalModifiedMetricType,
          values,
        );
        addMessage({
          messageGroupId: MessageGroupIds.REPORT_LEVEL,
          text: bannerText,
          dismissible: true,
          type: MessageBannerType.Success,
          autoDismissAfter: DEFAULT_SUCCESS_AUTO_DISMISS_AFTER_SEC,
        });
      }
    });
  }
}

export function generateReportTemplateCacheKeys(
  reportTemplatesInReportGroup: ReportTemplatesByKey,
  filters: Filter[],
) {
  return Object.keys(reportTemplatesInReportGroup).map((reportId) => [
    reportQueryString,
    reportId,
    filters,
  ]);
}

export function hasValidModifiedGoalMetricsValues(
  modifiedGoalMetricsMap: ModifiedGoalMetricsMap | undefined,
) {
  return Object.values(modifiedGoalMetricsMap || {}).some(
    (values) => values.length > 0,
  );
}
