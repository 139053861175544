import {
  Domain,
  PeriodType,
  TimespanComparison,
  TimespanComparisonType,
} from '@amzn/claritygqllambda';
import { DomainConfig } from '@clarity-website/config/domain-configs';
import {
  PeriodTypeUtilityMapping,
  generateRollingDateConfigs,
  generateSpecificDateConfigs,
} from '@clarity-website/reports/edit/timespan/periodTypeUtils';
import {
  PeriodAnchor,
  PopMetadata,
  RollingTimespanData,
  TimeAnchor,
  TimePresence,
  TimeUnit,
} from '@clarity-website/reports/edit/timespan/types';
import {
  DurationType,
  TemporalAdjuster,
  TemporalAdjusterFunction,
  TimespanSchema,
  TimespanType,
} from '@clarity-website/reports/filters/timespan-config';
import { isThisYear } from 'date-fns';

export const generateInitialRollingDateConfigs = (timespanConfigurationMap: {
  [key: string]: {
    timePresences: TimePresence[];
    periodAnchor: PeriodAnchor;
    timeAnchor: string[];
  };
}): {
  [key: string]: RollingTimespanData;
} => {
  return Object.entries(timespanConfigurationMap).reduce<{
    [key: string]: RollingTimespanData;
  }>((acc, [periodTypeString, timespanConfig]) => {
    timespanConfig.timePresences.forEach((timePresence) => {
      const periodType =
        PeriodType[periodTypeString as keyof typeof PeriodType];
      const periodTypeUtilityMapping = PeriodTypeUtilityMapping[periodType]!;
      const timeUnit = periodTypeUtilityMapping.timeUnits[timePresence];
      const timeAnchors = timespanConfig?.timeAnchor
        ? TimeAnchor[timespanConfig?.timeAnchor[0] as keyof typeof TimeAnchor]
        : TimeAnchor.CurrentYear;
      if (!acc[timePresence] && timeUnit) {
        acc[timePresence] = {
          timeUnit,
          timePresence,
          value: 1,
          anchor:
            timePresence === TimePresence.Current
              ? TimeAnchor.CurrentYear
              : timeAnchors,
        };
      }
    });
    return acc;
  }, {});
};
export const initialRollingTimespanData = (
  domain: string,
  domainConfig?: DomainConfig,
): {
  [key: string]: RollingTimespanData;
} => {
  if (domain in initialRollingTimespanDataByDomain) {
    return initialRollingTimespanDataByDomain[domain];
  }
  if (!domainConfig) {
    return {
      [TimePresence.Last]: {
        timeUnit: TimeUnit.Months,
        timePresence: TimePresence.Last,
        value: 1,
        anchor: TimeAnchor.CurrentYear,
      },
    };
  }
  // Add all time presences
  return generateInitialRollingDateConfigs(
    domainConfig.timespanConfigurationMap,
  );
};

export const initialRollingTimespanDataByDomain: {
  [key: string]: { [key: string]: RollingTimespanData };
} = {
  [Domain.Location]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Days,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Hour,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Next]: {
      timeUnit: TimeUnit.Weeks,
      timePresence: TimePresence.Next,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  engage: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Days,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Hour,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Next]: {
      timeUnit: TimeUnit.Weeks,
      timePresence: TimePresence.Next,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  [Domain.Organization]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.PerformanceYearToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  connections: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  talent_acquisition: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.YearToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  [Domain.OrganizationRow]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.PerformanceYearToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  [Domain.Rto]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Days,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  [Domain.RtoRow]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Days,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  [Domain.PiBusinessReview]: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Weeks,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.MonthToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  people_engine_requisition: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  people_engine_requisition_row: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  people_engine_recruiting: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  people_engine_recruiting_row: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  people_engine_interview: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  people_engine_interview_row: {
    [TimePresence.Current]: {
      timeUnit: TimeUnit.Day,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.Today,
    },
  },
  everybody: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.YearToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
  everybody_row: {
    [TimePresence.Last]: {
      timeUnit: TimeUnit.Months,
      timePresence: TimePresence.Last,
      value: 1,
      anchor: TimeAnchor.CurrentYear,
    },
    [TimePresence.Current]: {
      timeUnit: TimeUnit.YearToDate,
      timePresence: TimePresence.Current,
      anchor: TimeAnchor.CurrentYear,
    },
  },
};

export const selectedPlaceholders: { [key: string]: string } = {
  Days: 'MM-DD-YYYY',
  Weeks: 'YYYY-Wk-Number',
  Months: 'YYYY-MM',
  Quarters: 'YYYY-QQ',
  Years: 'YYYY',
};

export const timeUnitDisplayValues: { [key in TimeUnit]: string } = {
  Hour: 'Hour',
  Hours: 'Hours',
  Day: 'Day',
  Days: 'Days',
  CalendarWeek: 'Calendar Week',
  Weeks: 'Weeks',
  WeekToDate: 'WTD',
  Months: 'Months',
  MonthToDate: 'MTD',
  T3m: 'T3M',
  T6m: 'T6M',
  Ttm: 'TTM',
  Quarters: 'Quarters',
  QuarterToDate: 'QTD',
  Years: 'Years',
  YearToDate: 'YTD',
  PerformanceYears: 'Performance Years',
  PerformanceYearToDate: 'Performance YTD',
  PerformanceMonths: 'Performance Months',
  PerformanceMonthToDate: 'Performance MTD',
  PerformanceQuarterToDate: 'Performance QTD',
  PerformanceQuarters: 'Performance Quarters',
};

export const defaultTimeUnitRangesByAnchor: {
  [key: string]: { [key: string]: number };
} = {
  [TimeAnchor.CurrentYear]: {
    Hours: 24,
    Days: 60,
    Weeks: 55,
    CalendarWeek: 55,
    Months: 35,
    Quarters: 11,
    Years: 2,
    Ttm: 23,
    T6m: 29,
    T3m: 32,
    PerformanceMonths: 35,
    PerformanceYears: 2,
    PerformanceQuarters: 11,
  },
  [TimeAnchor.Year]: {
    Hours: 24,
    Days: 60,
    Weeks: 52,
    CalendarWeek: 52,
    Months: 23,
    Quarters: 7,
    Years: 1,
    Ttm: 11,
    T6m: 17,
    T3m: 20,
    PerformanceMonths: 23,
    PerformanceYears: 1,
    PerformanceQuarters: 7,
  },
  [TimeAnchor.TwoYears]: {
    Hours: 24,
    Days: 60,
    Weeks: 39,
    CalendarWeek: 39,
    Months: 11,
    Quarters: 3,
    Years: 0,
    Ttm: 0,
    T6m: 5,
    T3m: 8,
    PerformanceMonths: 11,
    PerformanceYears: 0,
    PerformanceQuarters: 3,
  },
};

export const nextTimeUnitRanges: { [key: string]: number } = {
  Weeks: 2,
};

export const getTimeUnitRanges = (
  timePresence: TimePresence,
  timeAnchor: TimeAnchor,
  domain: string,
): { [key: string]: number } => {
  if (timePresence === TimePresence.Next) {
    return nextTimeUnitRanges;
  }
  return (
    defaultTimeUnitRangesByAnchor[timeAnchor] ??
    defaultTimeUnitRangesByAnchor[TimeAnchor.CurrentYear]
  );
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthAbvNames = [
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
];

export const timeAnchorToTemporalAdjusterMap: {
  [key in TimeAnchor]: {
    temporalAdjusters: TemporalAdjuster[];
  };
} = {
  [TimeAnchor.Today]: {
    temporalAdjusters: [],
  },
  [TimeAnchor.CurrentYear]: {
    temporalAdjusters: [],
  },
  [TimeAnchor.Month]: {
    temporalAdjusters: [
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusMonths,
      },
    ],
  },
  [TimeAnchor.Quarter]: {
    temporalAdjusters: [
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusQuarters,
      },
    ],
  },
  [TimeAnchor.ThreeMonths]: {
    temporalAdjusters: [
      {
        arguments: ['3'],
        function: TemporalAdjusterFunction.minusMonths,
      },
    ],
  },
  [TimeAnchor.SixMonths]: {
    temporalAdjusters: [
      {
        arguments: ['6'],
        function: TemporalAdjusterFunction.minusMonths,
      },
    ],
  },
  [TimeAnchor.Year]: {
    temporalAdjusters: [
      {
        arguments: ['1'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
  },
  [TimeAnchor.TwoYears]: {
    temporalAdjusters: [
      {
        arguments: ['2'],
        function: TemporalAdjusterFunction.minusYears,
      },
    ],
  },
};

export const generateRollingDatesSelectionConfigs = (
  domain: string,
  domainConfig?: DomainConfig,
) => {
  if (domain in rollingDatesSelectionConfigs || !domainConfig) {
    return rollingDatesSelectionConfigs[domain];
  }
  return generateRollingDateConfigs(domainConfig?.timespanConfigurationMap);
};
/**
 * this data structure mimics the selection options in the timespan panel
 */
export const rollingDatesSelectionConfigs: {
  [key: string]: {
    [key in TimePresence]?: {
      [key in TimeUnit]?: {
        anchors: TimeAnchor[];
        timespanSchemaFn: (
          duration?: number,
          timeAnchor?: TimeAnchor,
          comparisons?: TimespanComparison[],
        ) => TimespanSchema;
        comparisonOptions?: PeriodType[];
      };
    };
  };
} = {
  [Domain.Location]: {
    [TimePresence.Current]: {
      [TimeUnit.Hour]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Hour,
          expression: '0 0 * * * ? *',
          periodType: PeriodType.Intraday,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfHour,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.CalendarWeek]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          type: TimespanType.Cron_Sequence,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          durationType: DurationType.Day,
          duration: 7,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
        }),
      },
      [TimeUnit.WeekToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.MonthToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
    [TimePresence.Next]: {
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (duration = 1, anchor = TimeAnchor.CurrentYear) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: ['1'],
              function: TemporalAdjusterFunction.plusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Hours]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (duration = 1, anchor = TimeAnchor.CurrentYear) => ({
          duration,
          durationType: DurationType.Hour,
          expression: '0 0 * * * ? *',
          periodType: PeriodType.Intraday,
          temporalAdjusters: [
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusHours,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfHour,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.Days]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusDays,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.CalendarWeek]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 7,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function:
                TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
            },
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Weekly],
      },
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
    },
  },
  engage: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.CalendarWeek]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          type: TimespanType.Cron_Sequence,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          durationType: DurationType.Day,
          duration: 7,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
        }),
      },
      [TimeUnit.WeekToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.MonthToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
    [TimePresence.Next]: {
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (duration = 1, anchor = TimeAnchor.CurrentYear) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: ['1'],
              function: TemporalAdjusterFunction.plusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Days]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusDays,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.CalendarWeek]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 7,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function:
                TemporalAdjusterFunction.startOfSundayWeekWithNowWeekNumber,
            },
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Weekly],
      },
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
    },
  },
  [Domain.Organization]: {
    [TimePresence.Current]: {
      [TimeUnit.PerformanceYearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Py,
          expression: '0 0 0 1 3 ? *',
          periodType: PeriodType.Py,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.PerformanceQuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Pq,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Pq,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.PerformanceMonthToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Pm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Pm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
      [TimeUnit.PerformanceMonths]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Pm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Pm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.PerformanceQuarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Pq,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Pq,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.PerformanceYears]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Py,
          expression: '0 0 0 1 3 ? *',
          periodType: PeriodType.Py,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
      [TimeUnit.Ttm]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Ttm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Ttm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.T3m]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.T3m,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.T3m,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.T6m]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.T6m,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.T6m,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
    },
  },
  connections: {
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Ttm]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Ttm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Ttm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
    },
  },
  talent_acquisition: {
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Ttm]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Ttm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Ttm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
    },
    [TimePresence.Current]: {
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
  },
  [Domain.OrganizationRow]: {
    [TimePresence.Current]: {
      [TimeUnit.PerformanceYearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfPerfYear,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.PerformanceMonthToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfMonth,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 3,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 3,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
      // ToDo: Add Performance quarters if prod requires sim: https://sim.amazon.com/issues/ClarityProdBacklog-1306
      [TimeUnit.PerformanceYears]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfPerfYear,
            },
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
    },
  },
  [Domain.Rto]: {
    [TimePresence.Last]: {
      [TimeUnit.Days]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusDays,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
    },
  },
  [Domain.RtoRow]: {
    [TimePresence.Last]: {
      [TimeUnit.Days]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusDays,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Daily, PeriodType.Weekly],
      },
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Weekly],
      },
    },
  },
  [Domain.PiBusinessReview]: {
    [TimePresence.Current]: {
      [TimeUnit.MonthToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Weeks]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Week,
          expression: '0 0 0 ? * SUN *',
          periodType: PeriodType.Weekly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusWeeks,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfSundayWeek,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Weekly],
      },
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
    },
  },
  people_engine_requisition: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  people_engine_requisition_row: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  people_engine_recruiting: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  people_engine_recruiting_row: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  people_engine_interview: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  people_engine_interview_row: {
    [TimePresence.Current]: {
      [TimeUnit.Day]: {
        anchors: [TimeAnchor.Today],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Day,
          expression: '0 0 0 * * ? *',
          periodType: PeriodType.Daily,
          temporalAdjusters: [
            {
              arguments: ['America/Los_Angeles'],
              function: TemporalAdjusterFunction.adjustTimezone,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfDay,
            },
          ],
          type: TimespanType.Current_Period,
        }),
      },
    },
  },
  everybody: {
    [TimePresence.Current]: {
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 1,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Quarter,
          expression: '0 0 0 1 */3 ? *',
          periodType: PeriodType.Quarterly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Year,
          expression: '0 0 0 1 1 ? *',
          periodType: PeriodType.Yearly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
      [TimeUnit.Ttm]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Ttm,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Ttm,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.T3m]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.T3m,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.T3m,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.T6m]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.T6m,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.T6m,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
    },
  },
  everybody_row: {
    [TimePresence.Current]: {
      [TimeUnit.YearToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
      [TimeUnit.QuarterToDate]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: () => ({
          duration: 3,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Cron_Sequence,
        }),
      },
    },
    [TimePresence.Last]: {
      [TimeUnit.Months]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [(duration - 1).toString()],
              function: TemporalAdjusterFunction.minusMonths,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Monthly, PeriodType.Yearly],
      },
      [TimeUnit.Quarters]: {
        anchors: [TimeAnchor.CurrentYear, TimeAnchor.Year, TimeAnchor.TwoYears],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 3,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusQuarters,
            },
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfQuarter,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Quarterly, PeriodType.Yearly],
      },
      [TimeUnit.Years]: {
        anchors: [TimeAnchor.CurrentYear],
        timespanSchemaFn: (
          duration = 1,
          anchor = TimeAnchor.CurrentYear,
          comparisons,
        ) => ({
          duration: duration * 12,
          durationType: DurationType.Month,
          expression: '0 0 0 1 * ? *',
          periodType: PeriodType.Monthly,
          temporalAdjusters: [
            {
              arguments: [],
              function: TemporalAdjusterFunction.maxAbsTimespan,
            },
            ...timeAnchorToTemporalAdjusterMap[anchor].temporalAdjusters,
            {
              arguments: [],
              function: TemporalAdjusterFunction.startOfYear,
            },
            {
              arguments: [duration.toString()],
              function: TemporalAdjusterFunction.minusYears,
            },
          ],
          type: TimespanType.Cron_Sequence,
          ...(comparisons && { comparisons }),
        }),
        comparisonOptions: [PeriodType.Yearly],
      },
    },
  },
};

export const generateSpecificDatesSelectionConfigs = (
  domain: string,
  domainConfig?: DomainConfig,
) => {
  if (domain in specificDatesSelectionConfigs) {
    return specificDatesSelectionConfigs[domain];
  }
  if (!domainConfig) {
    return specificDatesSelectionConfigs[Domain.Organization];
  }
  return generateSpecificDateConfigs(domainConfig.timespanConfigurationMap);
};

export const specificDatesSelectionConfigs: {
  [key: string]: {
    [key in TimeUnit]?: {
      timespanSchemaFn: (
        duration: number,
        epoch: number,
        timezone: string,
        comparisons?: TimespanComparison[],
      ) => TimespanSchema;
      getComparisonOptionsFn: (start?: Date, end?: Date) => PeriodType[];
    };
  };
} = {
  [Domain.Location]: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Daily,
        PeriodType.Weekly,
      ],
    },
    [TimeUnit.Weeks]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
    },
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Quarters]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Quarter,
        expression: '0 0 0 1 */3 ? *',
        periodType: PeriodType.Quarterly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfQuarter,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Quarterly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  engage: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Daily,
        PeriodType.Weekly,
      ],
    },
    [TimeUnit.Weeks]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
    },
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  [Domain.Organization]: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Quarters]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Quarter,
        expression: '0 0 0 1 */3 ? *',
        periodType: PeriodType.Quarterly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfQuarter,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Quarterly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  connections: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
  },
  talent_acquisition: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Quarters]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Quarter,
        expression: '0 0 0 1 */3 ? *',
        periodType: PeriodType.Quarterly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfQuarter,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Quarterly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  [Domain.OrganizationRow]: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
  },
  [Domain.Rto]: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Daily,
        PeriodType.Weekly,
      ],
    },
    [TimeUnit.Weeks]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
    },
  },
  [Domain.RtoRow]: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Daily,
        PeriodType.Weekly,
      ],
    },
    [TimeUnit.Weeks]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
    },
  },
  [Domain.PiBusinessReview]: {
    [TimeUnit.Weeks]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [PeriodType.Weekly],
    },
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  people_engine_requisition: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  people_engine_requisition_row: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  people_engine_recruiting: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  people_engine_recruiting_row: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  people_engine_interview: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  people_engine_interview_row: {
    [TimeUnit.Days]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Day,
        expression: '0 0 0 * * ? *',
        periodType: PeriodType.Daily,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfDay,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [],
    },
  },
  everybody: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Quarters]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Quarter,
        expression: '0 0 0 1 */3 ? *',
        periodType: PeriodType.Quarterly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfQuarter,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Quarterly,
        PeriodType.Yearly,
      ],
    },
    [TimeUnit.Years]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Year,
        expression: '0 0 0 1 1 ? *',
        periodType: PeriodType.Yearly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) =>
        start && isThisYear(start) ? [] : [PeriodType.Yearly],
    },
  },
  everybody_row: {
    [TimeUnit.Months]: {
      timespanSchemaFn: (duration = 1, epoch, timezone, comparisons) => ({
        duration,
        durationType: DurationType.Month,
        expression: '0 0 0 1 * ? *',
        periodType: PeriodType.Monthly,
        temporalAdjusters: [
          {
            arguments: [epoch.toString(), timezone],
            function: TemporalAdjusterFunction.identity,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfMonth,
          },
        ],
        type: TimespanType.Cron_Sequence,
        ...(comparisons && { comparisons }),
      }),
      getComparisonOptionsFn: (start?: Date, end?: Date) => [
        PeriodType.Monthly,
        PeriodType.Yearly,
      ],
    },
  },
};

export const maxAbsTimespanDurationTypes = [
  DurationType.Ttm,
  DurationType.T6m,
  DurationType.T3m,
];

export const POP_METADATA_MAP_BY_PERIOD_TYPE: { [key: string]: PopMetadata } = {
  [PeriodType.Daily]: {
    displayValue: 'DoD',
    postFix: '_dod',
    fullDisplayValue: 'Day-over-day',
  },
  [PeriodType.Weekly]: {
    displayValue: 'WoW',
    postFix: '_wow',
    fullDisplayValue: 'Week-over-week',
  },
  [PeriodType.Monthly]: {
    displayValue: 'MoM',
    postFix: '_mom',
    fullDisplayValue: 'Month-over-month',
  },
  [PeriodType.Quarterly]: {
    displayValue: 'QoQ',
    postFix: '_qoq',
    fullDisplayValue: 'Quarter-over-quarter',
  },
  [PeriodType.Yearly]: {
    displayValue: 'YoY',
    postFix: '_yoy',
    fullDisplayValue: 'Year-over-year',
  },
};

export const GOAL_COLUMNS = {
  goalTarget: {
    displayValue: 'Goal',
    columnSuffix: '_goal',
    sortKey: 'Goal_1_col',
  },
  deltaColumn: {
    displayValue: 'Delta',
    columnSuffix: '_delta',
    sortKey: 'Goal_2_col',
  },
};

export const COMPARISON_TYPES_AS_LIST = (
  Object.keys(TimespanComparisonType) as Array<
    keyof typeof TimespanComparisonType
  >
).reverse();

export const staticPopularTimespanIdsByDomain: { [key in Domain]?: string[] } =
  {
    [Domain.Location]: [
      // Col 1                      | Col 2
      // [ 1] Current Day           | [ 2] Last 8 Hours
      // [ 3] Current Calendar Week | [ 4] Last 1 Days
      // [ 5] Current WTD           | [ 6] Last 1 Weeks
      // [ 7] Current MTD           | [ 8] Last 4 Weeks
      // [ 9] Current QTD           | [10] Last 4 Months
      // [11] Last 1 Calendar week  | [12] Last 4 Quarters
      'Current_Period:0 0 0 * * ? *:Daily:Day:1:adjustTimezone^America/Los_Angeles#startOfDay^', // Current Day
      'Cron_Sequence:0 0 * * * ? *:Intraday:Hour:8:minusHours^8#startOfHour^', // Last 8 Hours
      'Cron_Sequence:0 0 0 * * ? *:Daily:Day:7:adjustTimezone^America/Los_Angeles#startOfSundayWeek^', // Current Calendar Week
      'Cron_Sequence:0 0 0 * * ? *:Daily:Day:1:adjustTimezone^America/Los_Angeles#minusDays^1#startOfDay^', // Last 1 Days
      'Current_Period:0 0 0 ? * SUN *:Weekly:Week:1:adjustTimezone^America/Los_Angeles#startOfSundayWeek^', // Current WTD
      'Cron_Sequence:0 0 0 ? * SUN *:Weekly:Week:1:adjustTimezone^America/Los_Angeles#startOfSundayWeekWithNowWeekNumber^#minusWeeks^1#startOfSundayWeek^', // Last 1 Weeks
      'Current_Period:0 0 0 1 * ? *:Monthly:Month:1:adjustTimezone^America/Los_Angeles#startOfMonth^', // Current MTD
      'Cron_Sequence:0 0 0 ? * SUN *:Weekly:Week:4:adjustTimezone^America/Los_Angeles#startOfSundayWeekWithNowWeekNumber^#minusWeeks^4#startOfSundayWeek^', // Last 4 Weeks
      'Current_Period:0 0 0 1 */3 ? *:Quarterly:Quarter:1:adjustTimezone^America/Los_Angeles#startOfQuarter^', // Current QTD
      'Cron_Sequence:0 0 0 1 * ? *:Monthly:Month:4:adjustTimezone^America/Los_Angeles#minusMonths^4#startOfMonth^', // Last 4 Months
      'Cron_Sequence:0 0 0 * * ? *:Daily:Day:7:adjustTimezone^America/Los_Angeles#minusWeeks^1#startOfSundayWeek^', // Last 1 Calendar Weeks
      'Cron_Sequence:0 0 0 1 */3 ? *:Quarterly:Quarter:4:adjustTimezone^America/Los_Angeles#minusQuarters^4#startOfQuarter^', // Last 4 Quarters
    ],
    [Domain.Organization]: [
      // Col 1                          | Col 2
      // [ 1] Last 1 Months             | [ 2] Last 1 Month - YoY
      // [ 3] Last 1 TTM                | [ 4] Last 1 TTM - MoM
      // [ 5] Last 1 Performance Months | [ 6] Last 1 Performance Months - YoY
      // [ 7] Last 3 Months             | [ 8] Current Performance MTD
      // [ 9] Last 1 T3M                | [10] Current Performance YTD
      // [11] Last 1 Years              | [12] Current YTD
      'Cron_Sequence:0 0 0 1 * ? *:Monthly:Month:1:maxAbsTimespan^#minusMonths^1', // Last 1 Months
      'Cron_Sequence:0 0 0 1 * ? *:Monthly:Month:1:maxAbsTimespan^#minusMonths^1:Yearly^latest', // Last 1 Months - YoY
      'Cron_Sequence:0 0 0 1 * ? *:Ttm:Ttm:1:maxAbsTimespan^#minusMonths^1', // Last 1 TTM
      'Cron_Sequence:0 0 0 1 * ? *:Ttm:Ttm:1:maxAbsTimespan^#minusMonths^1:Monthly^latest', // Last 1 TTM - MoM
      'Cron_Sequence:0 0 0 1 * ? *:Pm:Pm:1:maxAbsTimespan^#minusMonths^1', // Last 1 Performance Months
      'Cron_Sequence:0 0 0 1 * ? *:Pm:Pm:1:maxAbsTimespan^#minusMonths^1:Yearly^latest', // Last 1 Performance Months - YoY
      'Cron_Sequence:0 0 0 1 * ? *:Monthly:Month:3:maxAbsTimespan^#minusMonths^3', // Last 3 Months
      'Cron_Sequence:0 0 0 1 * ? *:Pm:Pm:1:maxAbsTimespan^#startOfMonth^', // Current Performance MTD
      'Cron_Sequence:0 0 0 1 * ? *:T3m:T3m:1:maxAbsTimespan^#minusMonths^1', // Last 1 T3M
      'Cron_Sequence:0 0 0 1 3 ? *:Py:Py:1:maxAbsTimespan^', // Current Performance YTD
      'Cron_Sequence:0 0 0 1 1 ? *:Yearly:Year:1:maxAbsTimespan^#minusYears^1', // Last 1 Years
      'Cron_Sequence:0 0 0 1 1 ? *:Yearly:Year:1:maxAbsTimespan^', // Current YTD
    ],
  };
