import { ValueType } from '@amzn/claritygqllambda';
import {
  ProcessedRowWithMetadata as ReportRow,
  ReportTableCell,
} from '@clarity-website/react-table-data-handler/common/types';
import { formatCellValueWrapper } from '@clarity-website/react-table-data-handler/common/utils';
import CommonTableMethods from '@clarity-website/reports/CommonTableMethods';
import { getColumnMeta } from '@clarity-website/reports/table/reportRendererHelpers';
import { UnprocessedRawRowValues as CellLike } from '@clarity-website/reports/useFetchRawReport';
import { Column, Header, Row, Table, memo } from '@tanstack/react-table';

export enum FilterConditionOperator {
  BETWEEN = 'BETWEEN',
  EQ = 'EQ',
  GT = 'GT',
  GE = 'GE',
  LT = 'LT',
  LE = 'LE',
  NE = 'NE',
}

export const conditionOperatorText: Record<FilterConditionOperator, string> = {
  [FilterConditionOperator.BETWEEN]: 'Between',
  [FilterConditionOperator.EQ]: 'Equal',
  [FilterConditionOperator.GT]: 'Greater than',
  [FilterConditionOperator.GE]: 'Greater than or equal to',
  [FilterConditionOperator.LT]: 'Less than',
  [FilterConditionOperator.LE]: 'Less than or equal to',
  [FilterConditionOperator.NE]: 'Not equal',
};

export interface ReportColumnFilter {
  displayValues?: Set<string>;
  condition?: {
    operator: FilterConditionOperator;
    operand1?: string;
    operand2?: string;
  };
}

export const SELECT_ALL = 'select-all';
export const BLANKS = ' ';

export function getFacetedUniqueValues(
  table: Table<ReportRow>,
  columnId: string,
) {
  return memo(
    // https://github.com/TanStack/table/blob/958ae6e90262b36e6d0af0b19a963dede933a8a6/packages/table-core/src/utils/getFacetedUniqueValues.ts#L10C2-L10C2
    // We use a different dependency, the 'preFilteredRowModel' instead of the 'facetedRowModel', so use this instead.
    () => [table.getPreFilteredRowModel()],
    () => {
      const result = table
        .getPreFilteredRowModel()
        .flatRows.reduce<
          Record<string, { cell: ReportTableCell; count: number }>
        >((result, row) => {
          const cellValue = row.getValue<ReportTableCell>(columnId) || {
            value: BLANKS,
            valueType: ValueType.String,
          };
          if (!row.original.metadata?.isSubtotalRow) {
            result[cellValue.value] = {
              cell: cellValue,
              count: (result[cellValue.value]?.count || 0) + 1,
            };
            return result;
          }
          return result;
        }, {});

      return new Map(Object.values(result).map((x) => [x.cell, x.count]));
    },
    { key: `faceted-unique-values-${columnId}` },
  );
}

export function columnFilter<TData>(
  row: Row<TData>,
  columnId: string,
  filterValue: ReportColumnFilter,
) {
  return (
    applyDisplayValuesFilter(row, columnId, filterValue) &&
    applyConditionFilter(row, columnId, filterValue)
  );
}

function applyDisplayValuesFilter<TData>(
  row: Row<TData>,
  columnId: string,
  filter?: ReportColumnFilter,
) {
  const cell = row.getValue<ReportTableCell>(columnId);
  return (
    !filter?.displayValues ||
    filter.displayValues.size === 0 ||
    !filter.displayValues.has(
      formatCellValueWrapper(cell.value, cell.valueType),
    )
  );
}

function applyConditionFilter<TData>(
  row: Row<TData>,
  columnId: string,
  filter?: ReportColumnFilter,
) {
  if (!filter?.condition?.operator) {
    return true;
  }

  const cellValue = row.getValue<ReportTableCell>(columnId);
  const { operator, operand1, operand2 } = filter.condition;

  const comparator = cellValueComparator(cellValue);

  const operatorFilterFunction: Record<
    FilterConditionOperator,
    (op1?: string, op2?: string) => boolean
  > = {
    [FilterConditionOperator.BETWEEN]: (op1, op2) =>
      comparator((a, b) => a >= b, op1) && comparator((a, b) => a <= b, op2),
    [FilterConditionOperator.EQ]: (op1) => comparator((a, b) => a === b, op1),
    [FilterConditionOperator.GT]: (op1) => comparator((a, b) => a > b, op1),
    [FilterConditionOperator.GE]: (op1) => comparator((a, b) => a >= b, op1),
    [FilterConditionOperator.LT]: (op1) => comparator((a, b) => a < b, op1),
    [FilterConditionOperator.LE]: (op1) => comparator((a, b) => a <= b, op1),
    [FilterConditionOperator.NE]: (op1) => comparator((a, b) => a !== b, op1),
  };

  return operatorFilterFunction[operator](operand1, operand2);
}

function parseFloatValue(value: string): number {
  const float = parseFloat(value);
  if (isNaN(float)) {
    return -Number.MAX_VALUE;
  }
  return float;
}

function parseIntValue(value: string): number {
  const int = parseInt(value);
  if (isNaN(int)) {
    return -Number.MAX_VALUE;
  }
  return int;
}

function parseBpsValue(value: string): number {
  const bps = Math.round(parseFloat(value) * 10000);
  if (isNaN(bps)) {
    return -Number.MAX_VALUE;
  }
  return bps;
}

export function valueTypeCompare(
  { value: aValue = BLANKS, valueType: aType = ValueType.String }: CellLike,
  { value: bValue = BLANKS, valueType: bType = ValueType.String }: CellLike,
): number {
  if (aType === ValueType.Lock || aValue === ValueType.Missing) {
    return 1;
  }

  if (bType === ValueType.Lock || bValue === ValueType.Missing) {
    return -1;
  }

  if (aType !== bType) {
    if (aType === ValueType.BasisPoints) {
      return -1;
    }

    if (bType === ValueType.BasisPoints) {
      return 1;
    }
  }

  if (
    aType === ValueType.Currency ||
    aType === ValueType.Float ||
    aType === ValueType.Percentage
  ) {
    return parseFloatValue(aValue) - parseFloatValue(bValue);
  } else if (aType === ValueType.Integer) {
    return parseIntValue(aValue) - parseIntValue(bValue);
  } else if (aType === ValueType.BasisPoints) {
    return parseBpsValue(aValue) - parseBpsValue(bValue);
  }

  return aValue.localeCompare(bValue, undefined, { numeric: true });
}

type ValueComparator<T> = (a: T, b: T) => boolean;
type TypedValueComparator = (
  comparator: ValueComparator<string | number>,
  valueToCompare?: string,
) => boolean;

const commonTableMethods = CommonTableMethods();

function cellValueComparator({
  value,
  valueType = ValueType.String,
}: ReportTableCell): TypedValueComparator {
  return (comparator, valueToCompare) => {
    if (!valueToCompare) {
      return true;
    }

    if (
      [
        ValueType.Currency,
        ValueType.Float,
        ValueType.Percentage,
        ValueType.BasisPoints,
        ValueType.Integer,
      ].includes(valueType)
    ) {
      const parsedValue = commonTableMethods.parseValueFromValueType(
        `${value}`,
        valueType,
      );
      return (
        parsedValue !== null &&
        comparator(parsedValue, parseFloat(valueToCompare))
      );
    }

    return comparator(value, valueToCompare);
  };
}

export function getIsColumnActionsButton<TData, TValue>(
  header: Header<TData, TValue>,
) {
  const isTimespanPopColumn = getColumnMeta(header.column)?.type
    ?.popTimespanColumn;
  const isChildOfPopColumn = getColumnMeta(header.column.parent)?.type
    ?.popTimespanColumn;
  const isNonPopLastLevelHeader =
    header.subHeaders.length === 0 && !isChildOfPopColumn;
  return (
    (isTimespanPopColumn || isNonPopLastLevelHeader) && {
      column: isTimespanPopColumn ? header.subHeaders[0].column : header.column,
    }
  );
}

export function getIsColumnFiltered<TData, TValue>(
  column: Column<TData, TValue>,
) {
  if (column.getIsFiltered()) {
    const filter = column.getFilterValue() as ReportColumnFilter;
    if (
      filter.displayValues?.size !== 0 ||
      (filter.condition?.operator && filter.condition?.operand1)
    ) {
      return true;
    }
    return false;
  }
  return false;
}
