import { Domain } from '@amzn/claritygqllambda';
import { DomainConfigs } from '@clarity-website/config/domain-configs';
import useDomainConfigs from '@clarity-website/config/useDomainConfigs';
import ClarityClient from '@clarity-website/lib/clients/ClarityClient';
import constructFilterConfig, {
  FilterDictionaryType,
} from '@clarity-website/reports/filters/constructFilterConfig';
import { FilterConfigInterface } from '@clarity-website/reports/filters/filter-config';
import {
  AttributeInterface,
  MetricsInterface,
} from '@clarity-website/reports/filters/filter-types';
import {
  GET_AVAILABLE_ATTRIBUTES_AND_METRICS,
  USERS_FAVORITE_REPORTS,
} from '@clarity-website/reports/report-queries';
import { oneDayInMs } from '@clarity-website/utils/cache-time-constants';
import {
  QueryClient,
  QueryObserverResult,
  useQuery,
} from '@tanstack/react-query';
import { equals } from 'ramda';
import { useState } from 'react';

export const filterInfoQuery = 'filterInfo';
export const reportGroupQuery = 'reportGroup';
export const reportGroupsQuery = 'reportGroups';
export const favoritesQueryKey = 'usersFavoriteReports';

const getAttributesAndMetrics = async (
  domain: string,
  domainConfigs?: DomainConfigs,
) => {
  const data = await new ClarityClient().gql({
    request: GET_AVAILABLE_ATTRIBUTES_AND_METRICS,
    variables: {
      domain,
    },
  });

  return {
    ...data,
    ...constructFilterConfig(
      data.getAvailableAttributes,
      data.getAvailableMetrics,
      domain,
      domainConfigs?.config[domain]?.isRowLevelDomain,
    ),
  };
};

type AttsAndMetsQueryType = {
  getAvailableAttributes: AttributeInterface[];
  getAvailableMetrics: MetricsInterface[];
};
export type UseFetchAttsAndMetsType = AttsAndMetsQueryType & {
  filterConfig: FilterConfigInterface;
  filterDictionary: FilterDictionaryType;
};

export function useFetchAttsAndMets(
  domain?: string,
  enabled = true,
): QueryObserverResult<UseFetchAttsAndMetsType> {
  const { data: domainConfigs } = useDomainConfigs();
  return useQuery(
    [filterInfoQuery, { domain: domain || Domain.Location }],
    () => getAttributesAndMetrics(domain || Domain.Location, domainConfigs),
    {
      staleTime: oneDayInMs,
      enabled,
    },
  );
}

// Optimistically fetches attsAndMets to reduce wait time
// If not used within cacheTime, will be garbage collected
export function prefetchAttsAndMets(
  queryClient: QueryClient,
  domainConfigs?: DomainConfigs,
  domain?: string,
  cacheTime?: number,
) {
  return queryClient.prefetchQuery(
    [filterInfoQuery, { domain: domain || Domain.Location }],
    () => getAttributesAndMetrics(domain || Domain.Location, domainConfigs),
    {
      cacheTime: cacheTime || oneDayInMs,
    },
  );
}

export function prefetchFavoriteReports(client: QueryClient) {
  client.prefetchQuery([favoritesQueryKey], getFavorites);
}

export default function useFavoriteReports(options: {
  onError: (error: Error) => void;
}): QueryObserverResult<string[], Error> {
  return useQuery<string[], Error>([favoritesQueryKey], getFavorites, {
    staleTime: oneDayInMs,
    onError: (error) => {
      // https://github.com/tannerlinsley/@tanstack/react-query/discussions/1179
      const isCancelledError =
        error && error.constructor.name === 'CancelledError';
      if (isCancelledError) {
        return;
      }

      options.onError(error);
    },
  });
}

const getFavorites = async (): Promise<string[]> => {
  const data = await new ClarityClient().gql<{
    usersFavoriteReports: string[];
  }>({
    request: USERS_FAVORITE_REPORTS,
  });
  return data.usersFavoriteReports;
};

export function useIsPristine<T>(fluctuatingValues: T) {
  const [initialValues] = useState<T>(fluctuatingValues);
  return equals(initialValues, fluctuatingValues);
}
